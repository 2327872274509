(function (exports) {
    "use strict";

    exports.URL = {};
    exports.URL.CREATE = "/operations/organizational-management/switches/FN-OML-007_create-transition/FN-OML-007_create-transition.php";
    exports.URL.VIEW = "/operations/organizational-management/switches/FN-OML-008_view-transitions/FN-OML-008_view-transitions.php";
    exports.URL.EDIT = "/operations/organizational-management/switches/FN-OML-009_edit-transition/FN-OML-009_edit-transition.php";
    exports.URL.REMOVE = "/operations/organizational-management/switches/FN-OML-010_delete-transition/FN-OML-010_delete-transition.php";

    exports.create = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Transitions", "create", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.CREATE, call, "Create Transitions")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.view = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Transitions", "view", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.VIEW, call, "View Transitions")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.edit = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Transitions", "edit", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.EDIT, call, "Edit Transitions")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.remove = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Transitions", "remove", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REMOVE, call, "Remove Transitions")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };
})(typeof exports === 'undefined' ? this['Transitions'] = {} : exports);