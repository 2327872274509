(function (exports) {
    "use strict";

    exports.URL = {};
    exports.URL.CREATE = "/operations/schedule-management/FN-SMN-016_create-batch/FN-SMN-016_create-batch.php";
    exports.URL.VIEW = "/operations/schedule-management/FN-SMN-017_view-batch/FN-SMN-017_view-batch.php";
    exports.URL.EDIT = "/operations/schedule-management/FN-SMN-018_edit-batch/FN-SMN-018_edit-batch.php";
    exports.URL.REMOVE = "/operations/schedule-management/FN-SMN-019_delete-batch/FN-SMN-019_delete-batch.php";
    
    exports.URL.CREATE_GAMES = "/operations/schedule-management/FN-SMN-021_create-batch-games/FN-SMN-021_create-batch-games.php";
    exports.URL.SCHEDULE_GAMES = "/operations/schedule-management/FN-SMN-020_schedule-batch-games/FN-SMN-020_schedule-batch-games.php";

    exports.URL.SANITY_CHECKS = "/operations/schedule-management/FN-SMN-023_sanity-checks/FN-SMN-023_sanity-checks.php";

    exports.URL.ROUND_CHECK = "/operations/schedule-management/FN-SMN-024_round-completeness-check/FN-SMN-024_round-completeness-check.php";

    exports.create = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("SchedulingBatches", "create", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.CREATE, call, "Create SchedulingBatch")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.view = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("SchedulingBatches", "view", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.VIEW, call, "View SchedulingBatch")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.edit = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("SchedulingBatches", "edit", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.EDIT, call, "Edit SchedulingBatch")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.remove = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("SchedulingBatches", "remove", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REMOVE, call, "Remove SchedulingBatch")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.create_games = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("SchedulingBatches", "create_games", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.CREATE_GAMES, call, "Create Games")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.schedule_games = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("SchedulingBatches", "schedule_games", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.SCHEDULE_GAMES, call, "Schedule Games")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.sanity_checks = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("SchedulingBatches", "sanity_checks", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.SANITY_CHECKS, call, "Sanity Checks")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.round_check = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("SchedulingBatches", "round_check", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.ROUND_CHECK, call, "Round Check")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };
})(typeof exports === 'undefined' ? this['SchedulingBatches'] = {} : exports);