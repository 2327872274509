(function (exports) {
    "use strict";

    exports.URL = {};
    exports.URL.CREATE = "/operations/organizational-management/venues/FN-OMV-007_create-availability/FN-OMV-007_create-availability.php";
    exports.URL.EDIT = "/operations/organizational-management/venues/FN-OMV-008_edit-availability/FN-OMV-008_edit-availability.php";
    exports.URL.REMOVE = "/operations/organizational-management/venues/FN-OMV-009_delete-availability/FN-OMV-009_delete-availability.php";

    exports.URL.FIND = "/operations/schedule-management/FN-SMN-022_find-availabilities/FN-SMN-022_find-availabilities.php";


    exports.URL.OPTIMIZATIONS = {};

    exports.URL.OPTIMIZATIONS.OPEN_AVAILABILITIES__GET_FILTERS = "/operations/optimizations/open-availabilities/get-filters/get-filters.php";
    exports.URL.OPTIMIZATIONS.OPEN_AVAILABILITIES__GET_AVAILABILITIES = "/operations/optimizations/open-availabilities/get-availabilities/get-availabilities.php";


    exports.create = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("VenueAvailabilities", "create", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.CREATE, call, "Create Venue Availability")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.edit = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("VenueAvailabilities", "edit", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.EDIT, call, "Edit Venue Availability")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.remove = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("VenueAvailabilities", "remove", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REMOVE, call, "Remove Venue Availability")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.find = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("VenueAvailabilities", "find", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.FIND, call, "Find Venue Availabilities")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };


    exports.optimizations = {};
    exports.optimizations.open_availabilities__get_filters = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("VenueAvailabilities", "open_availabilities__get_filters", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.OPTIMIZATIONS.OPEN_AVAILABILITIES__GET_FILTERS, call, "Open Availabilities: Get Filters")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.optimizations.open_availabilities__get_availabilities = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("VenueAvailabilities", "open_availabilities__get_availabilities", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.OPTIMIZATIONS.OPEN_AVAILABILITIES__GET_AVAILABILITIES, call, "Open Availabilities: Get Availabilities")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };
})(typeof exports === 'undefined' ? this['VenueAvailabilities'] = {} : exports);