(function (exports) {
    "use strict";

    exports.URL = {};
    exports.URL.CREATE = "/operations/organizational-management/groups/FN-OMG-001_create-group/FN-OMG-001_create-group.php";
    exports.URL.EDIT = "/operations/organizational-management/groups/FN-OMG-002_edit-group/FN-OMG-002_edit-group.php";
    exports.URL.REMOVE = "/operations/organizational-management/groups/FN-OMG-005_delete-group/FN-OMG-005_delete-group.php";

    exports.URL.ADD_TEAM = "/operations/organizational-management/groups/FN-OMG-003_add-team/FN-OMG-003_add-team.php";
    exports.URL.REMOVE_TEAM = "/operations/organizational-management/groups/FN-OMG-004_remove-team/FN-OMG-004_remove-team.php";

    exports.URL.GET_GROUP_TREE = "/operations/schedule-management/FN-SMN-015_get-group-tree/FN-SMN-015_get-group-tree.php";

    exports.create = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Groups", "create", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.CREATE, call, "Create Group")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.edit = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Groups", "edit", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.EDIT, call, "Edit Group")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.remove = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Groups", "remove", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REMOVE, call, "Remove Group")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.add_team = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Groups", "add_team", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.ADD_TEAM, call, "Add Team to Group")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.remove_team = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Groups", "remove_team", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REMOVE_TEAM, call, "Remove Team from Group")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.get_group_tree = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Groups", "get_group_tree", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.GET_GROUP_TREE, call, "Get Group Tree")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };
})(typeof exports === 'undefined' ? this['Groups'] = {} : exports);