(function (exports) {
    "use strict";

    exports.URL = {};
    exports.URL.FIND = "/operations/organizational-management/seasonal-configurations/FN-OMC-001_find-seasonal-configurations/FN-OMC-001_find-seasonal-configurations.php";
    exports.URL.EDIT = "/operations/organizational-management/seasonal-configurations/FN-OMC-002_edit-seasonal-configuration/FN-OMC-002_edit-seasonal-configuration.php";
    exports.URL.VIEW = "/operations/organizational-management/seasonal-configurations/FN-OMC-003_view-effective-seasonal-configuration/FN-OMC-003_view-effective-seasonal-configuration.php";
    exports.URL.COMPUTED = "/operations/organizational-management/seasonal-configurations/FN-OMC-003_view-effective-seasonal-configuration/FN-OMC-003_view-effective-seasonal-configuration.php";
    exports.URL.CLONE = "/operations/organizational-management/seasonal-configurations/FN-OMC-004_clone-seasonal-configurations/FN-OMC-004_clone-seasonal-configurations.php";
    exports.URL.EXTERNAL_ZONE_CONFIGURATION = "/operations/organizational-management/seasonal-configurations/FN-OMC-005_configure-external-zone/FN-OMC-005_configure-external-zone.php";

    exports.find = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("SeasonalConfigurations", "create", config);
        }

        var call = Security.wrapData(data);
        var loadConfig = {
            name: "Find Seasonal Configurations",
            url: exports.URL.FIND,
            callArrayName: "jurisdiction-ids",
            responseArrayName: "seasonal-configurations",
            successFunc: config.successFunc,
            failureFunc: config.failureFunc,
            delayFunc: config.delayFunc
        };

        Utilities.loadSet(call, loadConfig);
    };

    exports.view = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("SeasonalConfigurations", "view", config);
        }

        var newConfig = {
            successFunc: (function (config) {
                return function (err, parameters, url, id, textStatus, jqXHR) {
                    parameters.data = parameters.data.fees[0];
                    config.successFunc(err, parameters, url, id, textStatus, jqXHR);
                }
            }(config)),
            failureFunc: config.failureFunc,
            delayFunc: config.delayFunc
        };

        exports.find({
            "season-id": data["season-id"],
            "jurisdiction-ids": [data["jurisdiction-id"]],
            "offset": 0,
            "limit": 100
        }, config);
    };

    exports.edit = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("SeasonalConfigurations", "edit", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.EDIT, call, "Edit Seasonal Configurations")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.view_computed = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("SeasonalConfigurations", "view_computed", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.COMPUTED, call, "View Computed Seasonal Configuration")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.clone = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("SeasonalConfigurations", "clone", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.CLONE, call, "Clone Seasonal Configurations")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.configure_external_zones = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("SeasonalConfigurations", "configure external zones", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.EXTERNAL_ZONE_CONFIGURATION, call, "Configure external Zones")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };
})(typeof exports === 'undefined' ? this['SeasonalConfigurations'] = {} : exports);