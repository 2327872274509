(function (exports) {
    "use strict";

    exports.URL = {};
    exports.URL.CREATE = "/operations/person-management/volunteer-management/FN-VOL-001_create-volunteer/FN-VOL-001_create-volunteer.php";
    exports.URL.FIND = "/operations/person-management/volunteer-management/FN-VOL-002_find-volunteer/FN-VOL-002_find-volunteer.php";
    exports.URL.FIND_APPLICABLE = "/operations/person-management/volunteer-management/FN-VOL-006_find-applicable-volunteers/FN-VOL-006_find-applicable-volunteers.php";
    exports.URL.EDIT = "/operations/person-management/volunteer-management/FN-VOL-003_edit-volunteer/FN-VOL-003_edit-volunteer.php";
    exports.URL.REMOVE = "/operations/person-management/volunteer-management/FN-VOL-004_delete-volunteer/FN-VOL-004_delete-volunteer.php";

    exports.URL.OPTIMIZATIONS = {};
    exports.URL.OPTIMIZATIONS.MANAGE_VOLUNTEERS__GET_VOLUNTEERS = "/operations/optimizations/manage-volunteers/get-volunteers/get-volunteers.php";
    exports.URL.OPTIMIZATIONS.MISMATCHED_VOLUNTEERS__GET_VOLUNTEERS = "/operations/optimizations/mismatched-volunteers/get-volunteers/get-volunteers.php";
    exports.URL.OPTIMIZATIONS.PLAYER_REGISTRATION_DETAILS__GET_VOLUNTEERS = "/operations/optimizations/player-registration-details/get-volunteers/get-volunteers.php";

    exports.URL.REPORTS = {};
    exports.URL.REPORTS.GENERAL = "/operations/person-management/volunteer-management/FN-VOL-005_general-report/FN-VOL-005_general-report.php";

    exports.create = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Volunteers", "create", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.CREATE, call, "Create Volunteer")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.find = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Volunteers", "find", config);
        }

        var call = Security.wrapData(data);
        var loadConfig = {
            name: "Find Volunteers",
            url: exports.URL.FIND,
            callArrayName: "volunteer-ids",
            responseArrayName: "volunteers",
            successFunc: config.successFunc,
            failureFunc: config.failureFunc,
            delayFunc: config.delayFunc
        };

        if (_.isUndefined(call.data["volunteer-ids"])) Utilities.loadAll(call, loadConfig);
        else Utilities.loadSet(call, loadConfig);
    };

    exports.find_applicable = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Volunteers", "find_applicable", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.FIND_APPLICABLE, call, "Find Applicable Volunteers")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.edit = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Volunteers", "edit", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.EDIT, call, "Edit Volunteer")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.remove = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Volunteers", "remove", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REMOVE, call, "Remove Volunteer")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.reports = {};
    exports.reports.general = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Volunteers", "reports.general", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REPORTS.GENERAL, call, "Volunteers: General")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.manage_volunteers__get_volunteers = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Volunteers", "manage_volunteers__get_volunteers", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.OPTIMIZATIONS.MANAGE_VOLUNTEERS__GET_VOLUNTEERS, call, "manage_volunteers__get_volunteers")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.mismatched_volunteers__get_volunteers = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Volunteers", "mismatched_volunteers__get_volunteers", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.OPTIMIZATIONS.MISMATCHED_VOLUNTEERS__GET_VOLUNTEERS, call, "mismatched_volunteers__get_volunteers")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.player_registration_details__get_volunteers = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Volunteers", "player_registration_details__get_volunteers", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.OPTIMIZATIONS.PLAYER_REGISTRATION_DETAILS__GET_VOLUNTEERS, call, "player_registration_details__get_volunteers")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };
})(typeof exports === 'undefined' ? this['Volunteers'] = {} : exports);