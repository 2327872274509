(function (exports) {
    "use strict";

    exports.URL = {};
    exports.URL.CREATE = "/operations/organizational-management/games/FN-OMM-001_create-game/FN-OMM-001_create-game.php";
    exports.URL.EDIT = "/operations/organizational-management/games/FN-OMM-002_edit-game/FN-OMM-002_edit-game.php";
    exports.URL.REMOVE = "/operations/organizational-management/games/FN-OMM-003_delete-game/FN-OMM-003_delete-game.php";

    exports.URL.SCORE = "/operations/schedule-management/FN-SMN-012_score-game/FN-SMN-012_score-game.php";
    exports.URL.NOTIFY_INCOMPLETE = "/operations/schedule-management/FN-SMN-013_warn-game-sheet-incomplete/FN-SMN-013_warn-game-sheet-incomplete.php";
    exports.URL.NOTIFY_GAME_SCORED_LATE = "/operations/schedule-management/FN-SMN-014_notify_game_scored_late/FN-SMN-014_notify_game_scored_late.php";
    exports.URL.NOTIFY_GAME_SHEET_LATE = "/operations/schedule-management/FN-SMN-028_notify_game_sheet_late/FN-SMN-028_notify_game_sheet_late.php";
    exports.URL.RESCHEDULE = "/operations/organizational-management/games/FN-OMM-007_reschedule-game/FN-OMM-007_reschedule-game.php";
    exports.URL.NOTIFY_RESCHEDULE = "/operations/organizational-management/games/FN-OMM-008_email-reschedule/FN-OMM-008_email-reschedule.php";
    exports.URL.DOWNLOAD_GAMESHEET = "/operations/organizational-management/games/FN-OMM-010_download-game-sheet/FN-OMM-010_download-game-sheet.php";

    exports.URL.OPTIMIZATIONS = {};
    exports.URL.OPTIMIZATIONS.GET_GAMES = "/operations/optimizations/schedule-by-day-of-week/get-games/get-games.php";
    exports.URL.OPTIMIZATIONS.SCORING_AND_DISCIPLINE = {};
    exports.URL.OPTIMIZATIONS.SCORING_AND_DISCIPLINE.GET_GAMES = "/operations/optimizations/scoring-and-discipline/get-games/get-games.php";

    exports.URL.REPORTS = {};
    exports.URL.REPORTS.REFEREE_FEEDBACK = "/operations/organizational-management/games/FN-OMM-009_referee-feedback-report/FN-OMM-009_referee-feedback-report.php";

    exports.create = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Games", "create", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.CREATE, call, "Create Game")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.edit = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Games", "edit", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.EDIT, call, "Edit Game")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.remove = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Games", "remove", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REMOVE, call, "Remove Game")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.score = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Games", "score", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.SCORE, call, "Score Game")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.notify_incomplete = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Games", "notify_incomplete", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.NOTIFY_INCOMPLETE, call, "Notify Game Sheet is Incomplete")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.notify_game_scored_late = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Games", "notify_game_scored_late", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.NOTIFY_GAME_SCORED_LATE, call, "Notify Game was scored late")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.notify_game_sheet_late = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Games", "notify_game_sheet_late", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.NOTIFY_GAME_SHEET_LATE, call, "Notify Game Sheet was late")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.reschedule = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Games", "reschedule", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.RESCHEDULE, call, "Reschedule")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.notify_reschedule = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Games", "notify_reschedule", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.NOTIFY_RESCHEDULE, call, "Notify Reschedule")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.download_gamesheet = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Games", "download_gamesheet", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.DOWNLOAD_GAMESHEET, call, "Download Gamesheet")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.optimizations__get_games = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Games", "optimizations__get_games", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.OPTIMIZATIONS.GET_GAMES, call, "Optimizations: Get Games")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.optimizations__scoring_and_discipline__get_games = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Games", "optimizations__scoring_and_discipline__get_games", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.OPTIMIZATIONS.SCORING_AND_DISCIPLINE.GET_GAMES, call, "Optimizations; Scoring and Discipline: Get Games")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };


    exports.reports__referee_feedback = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Games", "reports__referee_feedback", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REPORTS.REFEREE_FEEDBACK, call, "Reports: Referee Feedback")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };
})(typeof exports === 'undefined' ? this['Games'] = {} : exports);