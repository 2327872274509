(function (exports) {
    "use strict";

    exports.URL = {};
    exports.URL.CREATE = "/operations/person-management/notate-account/FN-ACN-001_create-note/FN-ACN-001_create-note.php";
    exports.URL.EDIT = "/operations/person-management/notate-account/FN-ACN-003_edit-note/FN-ACN-003_edit-note.php";
    exports.URL.REMOVE = "/operations/person-management/notate-account/FN-ACN-004_delete-note/FN-ACN-004_delete-note.php";
    exports.URL.FIND = "/operations/person-management/notate-account/FN-ACN-005_find-notes/FN-ACN-005_find-notes.php";

    exports.create = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Notes", "create", config);
        }

        var call = Security.wrapData(data);
        
        server.request(exports.URL.CREATE, call, "Create Note")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.edit = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Notes", "edit", config);
        }

        var call = Security.wrapData(data);
        
        server.request(exports.URL.EDIT, call, "Edit Note")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.remove = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Notes", "remove", config);
        }

        var call = Security.wrapData(data);
        
        server.request(exports.URL.REMOVE, call, "Remove Note")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.find = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Notes", "find", config);
        }

        var call = Security.wrapData(data);
        
        server.request(exports.URL.FIND, call, "Find Notes")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };
})(typeof exports === 'undefined' ? this['Notes'] = {} : exports);