(function (exports) {
    "use strict";

    exports.URL = {};
    exports.URL.CREATE = "/operations/person-management/FN-ENM-013_become-operator/FN-ENM-013_become-operator.php";
    exports.URL.FIND = "";
    exports.URL.VIEW = "";
    exports.URL.EDIT = "";
    exports.URL.REMOVE = "/operations/security/FN-SEC-005_remove-role/FN-SEC-005_remove-role.php";

    exports.URL.OPTIMIZATIONS = {};
    exports.URL.OPTIMIZATIONS.GET_OPERATORS = "/operations/optimizations/manage-operators/get-operators/get-operators.php";


    exports.create = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Operators", "create", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.CREATE, call, "Create Operators")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.find = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Operators", "find", config);
        }

        throw "[Operators.find]: not implemented";

        var call = Security.wrapData(data);
        var loadConfig = {
            name: "Find operators",
            url: exports.URL.FIND,
            callArrayName: "operator-ids",
            responseArrayName: "operators",
            successFunc: config.successFunc,
            failureFunc: config.failureFunc,
            delayFunc: config.delayFunc
        };

        if (_.isUndefined(call.data["operator-ids"])) Utilities.loadAll(call, loadConfig);
        else Utilities.loadSet(call, loadConfig);
    };

    exports.view = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Operators", "view", config);
        }

        throw "[Operators.view]: not implemented";

        var call = Security.wrapData(data);
        server.request(exports.URL.VIEW, call, "View Operators")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.edit = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Operators", "edit", config);
        }

        throw "[Operators.edit]: not implemented";

        var call = Security.wrapData(data);
        server.request(exports.URL.EDIT, call, "Edit Operators")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.remove = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Operators", "remove", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REMOVE, call, "Remove Operators")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };


    exports.optimizations__get_operators = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Operators", "get_operators", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.OPTIMIZATIONS.GET_OPERATORS, call, "Get Operators")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };
})(typeof exports === 'undefined' ? this['Operators'] = {} : exports);