(function (exports) {
    "use strict";

    exports.URL = {};
    exports.URL.CREATE = "/operations/person-management/FN-ENM-014_become-director/FN-ENM-014_become-director.php";
    exports.URL.FIND = "";
    exports.URL.VIEW = "";
    exports.URL.EDIT = "";
    exports.URL.REMOVE = "/operations/security/FN-SEC-005_remove-role/FN-SEC-005_remove-role.php";

    exports.URL.OPTIMIZATIONS = {};
    exports.URL.OPTIMIZATIONS.GET_OPERATORS = "/operations/optimizations/manage-directors/get-directors/get-directors.php";

    exports.create = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Directors", "create", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.CREATE, call, "Create Director")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.find = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Directors", "find", config);
        }

        throw "[Directors.find]: not implemented";

        var call = Security.wrapData(data);
        var loadConfig = {
            name: "Find Directors",
            url: exports.URL.FIND,
            callArrayName: "director-ids",
            responseArrayName: "directors",
            successFunc: config.successFunc,
            failureFunc: config.failureFunc,
            delayFunc: config.delayFunc
        };

        if (_.isUndefined(call.data["director-ids"])) Utilities.loadAll(call, loadConfig);
        else Utilities.loadSet(call, loadConfig);
    };

    exports.view = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Directors", "view", config);
        }

        throw "[Directors.view]: not implemented";

        var call = Security.wrapData(data);
        server.request(exports.URL.VIEW, call, "View Director")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.edit = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Directors", "edit", config);
        }

        throw "[Directors.edit]: not implemented";

        var call = Security.wrapData(data);
        server.request(exports.URL.EDIT, call, "Edit Director")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.remove = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Directors", "remove", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REMOVE, call, "Remove Director")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };


    exports.optimizations__get_directors = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Directors", "get_directors", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.OPTIMIZATIONS.GET_OPERATORS, call, "Get Directors")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };
})(typeof exports === 'undefined' ? this['Directors'] = {} : exports);