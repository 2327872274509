(function (exports) {
    "use strict";

    exports.URL = {};
    exports.URL.CREATE = "/operations/person-management/volunteer-management/subrole-management/FN-EVS-001_create-subrole/FN-EVS-001_create-subrole.php";
    exports.URL.FIND = "/operations/person-management/volunteer-management/subrole-management/FN-EVS-002_find-subrole/FN-EVS-002_find-subrole.php";
    exports.URL.EDIT = "/operations/person-management/volunteer-management/subrole-management/FN-EVS-003_edit-subrole/FN-EVS-003_edit-subrole.php";
    exports.URL.REMOVE = "/operations/person-management/volunteer-management/subrole-management/FN-EVS-004_delete-subrole/FN-EVS-004_delete-subrole.php";
    exports.URL.CLONE = "/operations/person-management/volunteer-management/subrole-management/FN-EVS-005_clone-subroles/FN-EVS-005_clone-subroles.php";


    exports.create = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Volunteer Subroles", "create", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.CREATE, call, "Create Volunteer Subrole")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.find = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Volunteer Subroles", "find", config);
        }

        var call = Security.wrapData(data);
        var loadConfig = {
            name: "Find Volunteer Subroles",
            url: exports.URL.FIND,
            callArrayName: "volunteer-subrole-ids",
            responseArrayName: "volunteer-subroles",
            successFunc: config.successFunc,
            failureFunc: config.failureFunc,
            delayFunc: config.delayFunc
        };

        if (_.isUndefined(call.data["volunteer-subrole-ids"])) Utilities.loadAll(call, loadConfig);
        else Utilities.loadSet(call, loadConfig);
    };

    exports.view = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Volunteer Subroles", "view", config);
        }

        var newConfig = {
            successFunc: (function (config) {
                return function (err, parameters, url, id, textStatus, jqXHR) {
                    parameters.data = parameters.data["volunteer-subroles"][0];
                    config.successFunc(err, parameters, url, id, textStatus, jqXHR);
                }
            }(config)),
            failureFunc: config.failureFunc,
            delayFunc: config.delayFunc
        };

        var newData = _.clone(data);
        delete newData["volunteer-subrole-id"];
        newData["volunteer-subrole-ids"] = [data["volunteer-subrole-id"]];
        newData.offset = 0;
        newData.limit = 1;

        exports.find(newData, newConfig);
    };

    exports.edit = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Volunteer Subroles", "edit", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.EDIT, call, "Edit Volunteer Subroles")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.remove = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Volunteer Subroles", "remove", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REMOVE, call, "Remove Volunteer Subroles")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.clone = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Volunteer Subroles", "clone", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.CLONE, call, "Clone Volunteer Subroles")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };
})(typeof exports === 'undefined' ? this['VolunteerSubroles'] = {} : exports);