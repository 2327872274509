(function (exports) {
    "use strict";

    exports.URL = {};
    exports.URL.CREATE = "/operations/person-management/team-official-management/qualifications/FN-ETQ-001_create-qualification/FN-ETQ-001_create-qualification.php";
    exports.URL.EDIT = "/operations/person-management/team-official-management/qualifications/FN-ETQ-003_edit-qualification-details/FN-ETQ-003_edit-qualification-details.php";
    exports.URL.REMOVE = "/operations/person-management/team-official-management/qualifications/FN-ETQ-004_delete-qualification/FN-ETQ-004_delete-qualification.php";
    
    exports.URL.ADD_TO_PERSON = "/operations/person-management/team-official-management/qualifications/FN-ETQ-005_add-qualification-to-person/FN-ETQ-005_add-qualification-to-person.php";
    exports.URL.REMOVE_FROM_PERSON = "/operations/person-management/team-official-management/qualifications/FN-ETQ-006_remove-qualification-from-person/FN-ETQ-006_remove-qualification-from-person.php";
    exports.URL.EDIT_INSTANCE = "/operations/person-management/team-official-management/qualifications/FN-ETQ-007_edit-qualification-instance/FN-ETQ-007_edit-qualification-instance.php";


    exports.create = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Qualifications", "create", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.CREATE, call, "Create Qualifications")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.edit = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Qualifications", "edit", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.EDIT, call, "Edit Qualifications")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.remove = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Qualifications", "remove", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REMOVE, call, "Remove Qualifications")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };
    

    exports.add_to_person = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Qualifications", "add_to_person", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.ADD_TO_PERSON, call, "Add Qualification to Person")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.remove_from_person = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Qualifications", "remove_from_person", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REMOVE_FROM_PERSON, call, "Remove Qualification from Perosn")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.edit_instance = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Qualifications", "edit_instance", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.EDIT_INSTANCE, call, "Edit Qualification Instance")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };
})(typeof exports === 'undefined' ? this['Qualifications'] = {} : exports);