(function (exports) {
    "use strict";

    exports.URL = {};
    exports.URL.CREATE = "/operations/person-management/FN-ENM-001_create-person/FN-ENM-001_create-person.php";
    exports.URL.FIND = "/operations/person-management/FN-ENM-002_find-person/FN-ENM-002_find-person.php";
    exports.URL.FIND_MINIMAL = "/operations/person-management/FN-ENM-026_find-person-minimal/FN-ENM-026_find-person-minimal.php";
    exports.URL.VIEW = "/operations/person-management/FN-ENM-003_view-person-details/FN-ENM-003_view-person-details.php";
    exports.URL.EDIT = "/operations/person-management/FN-ENM-004_edit-person-details/FN-ENM-004_edit-person-details.php";
    exports.URL.REMOVE = "/operations/person-management/FN-ENM-005_delete-person/FN-ENM-005_delete-person.php";
    exports.URL.MERGE = "/operations/person-management/FN-ENM-031_merge-persons/FN-ENM-031_merge-persons.php";
    exports.URL.SEND_EMAIL = "/operations/person-management/FN-ENM-021_send-email/FN-ENM-021_send-email.php";
    exports.URL.GET_CARD = "/operations/person-management/FN-ENM-022_download-id-card/FN-ENM-022_download-id-card.php";
    exports.URL.VIEW_PIC = "/operations/person-management/FN-ENM-023_view-pic-information/FN-ENM-023_view-pic-information.php";
    exports.URL.EDIT_PIC = "/operations/person-management/FN-ENM-024_edit-pic-information/FN-ENM-024_edit-pic-information.php";
    exports.URL.GET_OLD_PHOTO = "/operations/person-management/FN-ENM-025_get-old-card-photo/FN-ENM-025_get-old-card-photo.php";

    exports.URL.GET_PARENTS = "/operations/person-management/FN-ENM-027_find-parents-on-same-account/FN-ENM-027_find-parents-on-same-account.php";

    exports.URL.CHANGE_ACCOUNT = "/operations/person-management/FN-ENM-028_change-account/FN-ENM-028_change-account.php";
    exports.URL.MOVE_TO_NEW_ACCOUNT = "/operations/person-management/login-management/FN-ENL-016_move-to-new-account/FN-ENL-016_move-to-new-account.php";

    exports.URL.GET_ID_CARD_STATUSES = "/operations/person-management/FN-ENM-030_get-id-card-statuses/FN-ENM-030_get-id-card-statuses.php";
    exports.URL.EDIT_ID_CARD = "/operations/person-management/player-and-coach-cards/FN-ENC-006_edit-card/FN-ENC-006_edit-card.php";
    exports.URL.REMOVE_ID_CARD = "/operations/person-management/player-and-coach-cards/FN-ENC-005_delete-card/FN-ENC-005_delete-card.php";

    exports.URL.GET_DISCIPLINE = "/operations/person-management/player-and-coach-discipline/FN-END-009_view-discipline/FN-END-009_view-discipline.php";

    exports.URL.REPORTS = {};
    exports.URL.REPORTS.DUPLICATES = "/operations/reporting/pre-defined-reports/FN-PDR-008_duplicate-persons/FN-PDR-008_duplicate-persons.php";

    exports.create = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Persons", "create", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.CREATE, call, "Create Persons")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.find = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Persons", "find", config);
        }

        var call = Security.wrapData(data);
        var loadConfig = {
            name: "Find Persons",
            url: exports.URL.FIND,
            callArrayName: "person-ids",
            responseArrayName: "persons",
            successFunc: config.successFunc,
            failureFunc: config.failureFunc,
            delayFunc: config.delayFunc
        };

        if (_.isUndefined(call.data["person-ids"])) Utilities.loadAll(call, loadConfig);
        else Utilities.loadSet(call, loadConfig);
    };

    exports.find_minimal = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Persons", "find_minimal", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.FIND_MINIMAL, call, "Find Persons")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.view = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Persons", "view", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.VIEW, call, "View Persons")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.edit = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Persons", "edit", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.EDIT, call, "Edit Persons")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.remove = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Persons", "remove", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REMOVE, call, "Remove Persons")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.merge = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Persons", "merge", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.MERGE, call, "Merge Persons")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.send_email = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Persons", "send_email", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.SEND_EMAIL, call, "Send Email to Persons")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.get_card = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Persons", "get_card", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.GET_CARD, call, "Get ID card")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.view_pic = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Persons", "view_pic", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.VIEW_PIC, call, "View PIC")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.edit_pic = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Persons", "edit_pic", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.EDIT_PIC, call, "Edit PIC")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.get_parents = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Persons", "get_parents", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.GET_PARENTS, call, "Get Parents")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.change_account = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Persons", "change_account", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.CHANGE_ACCOUNT, call, "Change Account")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.move_to_new_account = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Persons", "move_to_new_account", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.MOVE_TO_NEW_ACCOUNT, call, "Move to New Account")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.get_id_card_statuses = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Persons", "get_id_card_statuses", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.GET_ID_CARD_STATUSES, call, "Get ID Card Statuses")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.edit_id_card = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Persons", "edit_id_card", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.EDIT_ID_CARD, call, "Edit ID Card")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.remove_id_card = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Persons", "remove_id_card", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REMOVE_ID_CARD, call, "Remove ID Card")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.get_discipline = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Persons", "get_discipline", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.GET_DISCIPLINE, call, "Get Discipline")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.reports = {};
    exports.reports.duplicates = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Persons", "reports.duplicates", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REPORTS.DUPLICATES, call, "Reports: Duplicates")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };
})(typeof exports === 'undefined' ? this['Persons'] = {} : exports);