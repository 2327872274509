(function (exports) {
    "use strict";

    exports.URL = {};
    exports.URL.CREATE = "/operations/person-management/player-management/FN-ENP-015_create-player/FN-ENP-015_create-player.php";
    exports.URL.CREATE_MONOLITHIC = "/operations/person-management/player-management/FN-ENP-023_monolithic-player-registration/FN-ENP-023_monolithic-player-registration.php";
    exports.URL.CREATE_MONOLITHIC_BY_OPERATOR = "/operations/person-management/player-management/FN-ENP-024_monolithic-player-registration-by-operator/FN-ENP-024_monolithic-player-registration-by-operator.php";
    exports.URL.FIND = "";
    exports.URL.FIND_POTENTIAL_PLAYERS = "/operations/person-management/player-management/FN-ENP-034_find-potential-players/FN-ENP-034_find-potential-players.php";
    exports.URL.VIEW = "/operations/person-management/player-management/FN-ENP-016_view-player-details/FN-ENP-016_view-player-details.php";
    exports.URL.EDIT = "/operations/person-management/player-management/FN-ENP-017_edit-player-details/FN-ENP-017_edit-player-details.php";
    exports.URL.REMOVE = "/operations/person-management/player-management/FN-ENP-021_delete-player/FN-ENP-021_delete-player.php";
    exports.URL.COMPLETE_REGISTRATION = "/operations/person-management/player-management/FN-ENP-035_complete-registration/FN-ENP-035_complete-registration.php";
    exports.URL.SEND_CONFIRMATION_EMAIL = "/operations/person-management/player-management/FN-ENP-036_send-confirmation-email/FN-ENP-036_send-confirmation-email.php";
    exports.URL.INITIATE_TRANSFER = "/operations/person-management/player-management/FN-ENP-040_initiate-transfer/FN-ENP-040_initiate-transfer.php";
    exports.URL.UNCANCEL = "/operations/person-management/player-management/FN-ENP-043_uncancel-player/FN-ENP-043_uncancel-player.php";

    exports.URL.CALCULATE_PROJECTED_FEES = "/operations/person-management/player-management/FN-ENP-028_calculate-projected-fees/FN-ENP-028_calculate-projected-fees.php";
    exports.URL.CALCULATE_VOLUNTEER_REQUIREMENTS = "/operations/person-management/player-management/FN-ENP-029_calculate-volunteer-requirements/FN-ENP-029_calculate-volunteer-requirements.php";
    exports.URL.MAKE_PAYMENT = "/operations/person-management/player-management/FN-ENP-030_make-payment/FN-ENP-030_make-payment.php";

    exports.URL.MANAGE_TRANSFER = "/operations/person-management/player-management/FN-ENP-020_manage-jurisdiction-change-request/FN-ENP-020_manage-jurisdiction-change-request.php";
    exports.URL.MANAGE_BUMP = "/operations/person-management/player-management/FN-ENP-019_manage-age-category-request/FN-ENP-019_manage-age-category-request.php";

    exports.URL.EDIT_AGE_COMMUNITY = "/operations/person-management/player-management/FN-ENP-001_transfer-player/FN-ENP-001_transfer-player.php";
    exports.URL.EDIT_JERSEY = "/operations/person-management/player-management/FN-ENP-044_edit-player-jersey/FN-ENP-044_edit-player-jersey.php";

    exports.URL.GET_TAX_RECEIPT = "/operations/person-management/player-management/FN-ENP-010_download-tax-receipts/FN-ENP-010_download-tax-receipts.php";
    exports.URL.FIND_TAX_RECEIPTS = "/operations/person-management/player-management/FN-ENP-039_list-tax-receipts/FN-ENP-039_list-tax-receipts.php";

    exports.URL.OPTIMIZATIONS = {};
    exports.URL.OPTIMIZATIONS.GET_TRANSFERS = "/operations/optimizations/manage-transfers/get-transfers/get-transfers.php";
    exports.URL.OPTIMIZATIONS.GET_TRANSFER_DETAILS = "/operations/optimizations/manage-transfers/get-transfer-details/get-transfer-details.php";
    exports.URL.OPTIMIZATIONS.GET_AGE_BUMPS = "/operations/optimizations/manage-age-bumps/get-age-bumps/get-age-bumps.php";
    exports.URL.OPTIMIZATIONS.MANAGE_PLAYERS__GET_PLAYERS = "/operations/optimizations/manage-players/get-players/get-players.php";
    exports.URL.OPTIMIZATIONS.EDIT_ROSTER__GET_UNASSIGNED_PLAYERS = "/operations/optimizations/edit-roster/get-unassigned-players/get-unassigned-players.php";
    exports.URL.OPTIMIZATIONS.PLAYER_REGISTRATION_DETAILS__GET_AGE_COMMUNITY = "/operations/optimizations/player-registration-details/get-age-community/get-age-community.php";
    exports.URL.OPTIMIZATIONS.PLAYER_REGISTRATION_DETAILS__GET_JU_AC = "/operations/optimizations/player-registration-details/get-available-ju-ac/get-available-ju-ac.php";
    exports.URL.OPTIMIZATIONS.PLAYER_REGISTRATION_DETAILS__GET_DV = "/operations/optimizations/player-registration-details/get-available-dv/get-available-dv.php";
    exports.URL.OPTIMIZATIONS.EDIT_PLAYER__GET_PLAYER_DATA = "/operations/optimizations/edit-player/get-player-data/get-player-data.php";

    exports.URL.REPORTS = {};
    exports.URL.REPORTS.GENERAL = "/operations/person-management/player-management/FN-ENP-037_general-report/FN-ENP-037_general-report.php";
    exports.URL.REPORTS.ON_TEAMS = "/operations/person-management/player-management/FN-ENP-025_player-report/FN-ENP-025_player-report.php";
    exports.URL.REPORTS.DUPLICATES = "/operations/person-management/player-management/FN-ENP-026_duplicate-player-report/FN-ENP-026_duplicate-player-report.php";
    exports.URL.REPORTS.INCOMPLETE_REGISTRATIONS = "/operations/person-management/player-management/FN-ENP-032_incomplete-registrations/FN-ENP-032_incomplete-registrations.php";
    exports.URL.REPORTS.CREDIT_CARD_PAYMENTS = "/operations/person-management/player-management/FN-ENP-033_credit-card-payments/FN-ENP-033_credit-card-payments.php";
    exports.URL.REPORTS.ALL_TRANSACTIONS = "/operations/person-management/player-management/FN-ENP-038_all-transactions/FN-ENP-038_all-transactions.php";
    exports.URL.REPORTS.DISCIPLINE = "/operations/person-management/player-management/FN-ENP-042_discipline-report/FN-ENP-042_discipline-report.php";
    exports.URL.REPORTS.RETURNING = "/operations/reporting/pre-defined-reports/FN-PDR-002_new-players/FN-PDR-002_new-players.php";
    exports.URL.REPORTS.DEMOGRAPHICS = "/operations/reporting/pre-defined-reports/FN-PDR-003_player-demographics/FN-PDR-003_player-demographics.php";
    exports.URL.REPORTS.ASA = "/operations/reporting/pre-defined-reports/FN-PDR-006_asa-players/FN-PDR-006_asa-players.php";
    exports.URL.REPORTS.UNSIGNED_SAR_WAIVERS = "/operations/reporting/pre-defined-reports/FN-PDR-014_player-unsigned-sar-waivers/FN-PDR-014_player-unsigned-sar-waivers.php";
    exports.URL.REPORTS.GRANULAR_DEMOGRAPHICS = "/operations/reporting/pre-defined-reports/FN-PDR-018_granular-player-demographics/FN-PDR-018_granular-player-demographics.php";

    exports.create = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Players", "create", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.CREATE, call, "Create Players")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.create_monolithic = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Players", "create_monolithic", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.CREATE_MONOLITHIC, call, "Create Player (Monolithic)")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.create_monolithic_by_operator = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Players", "create_monolithic_by_operator", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.CREATE_MONOLITHIC_BY_OPERATOR, call, "Create Player (Monolithic, Operator)")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.find = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Players", "find", config);
        }

        throw "[Players.find]: not implemented";

        var call = Security.wrapData(data);
        var loadConfig = {
            name: "Find Players",
            url: exports.URL.FIND,
            callArrayName: "player-ids",
            responseArrayName: "players",
            successFunc: config.successFunc,
            failureFunc: config.failureFunc,
            delayFunc: config.delayFunc
        };

        if (_.isUndefined(call.data["player-ids"])) Utilities.loadAll(call, loadConfig);
        else Utilities.loadSet(call, loadConfig);
    };

    exports.find_potential_players = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Players", "find_potential_players", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.FIND_POTENTIAL_PLAYERS, call, "Find Potential Players")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.view = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Players", "view", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.VIEW, call, "View Players")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.edit = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Players", "edit", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.EDIT, call, "Edit Players")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.remove = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Players", "remove", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REMOVE, call, "Remove Players")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.complete_registration = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Players", "complete_registration", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.COMPLETE_REGISTRATION, call, "Complete Player Registration")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.send_confirmation_email = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Players", "send_confirmation_email", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.SEND_CONFIRMATION_EMAIL, call, "Player Send Confirmation Email")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.initiate_transfer = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Players", "initiate_transfer", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.INITIATE_TRANSFER, call, "Player > Initiate Transfer")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.uncancel = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Players", "uncancel", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.UNCANCEL, call, "Uncancel Player")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.calculate_projected_fees = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Players", "calculate_projected_fees", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.CALCULATE_PROJECTED_FEES, call, "Calculate Projected Fees")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.calculate_volunteer_requirements = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Players", "calculate_volunteer_requirements", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.CALCULATE_VOLUNTEER_REQUIREMENTS, call, "Calculate Volunteer Requirements")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.make_payment = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Players", "make_payment", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.MAKE_PAYMENT, call, "Make Payment")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.manage_transfer = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Players", "manage_transfer", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.MANAGE_TRANSFER, call, "Manage Transfers")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.manage_bump = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Players", "manage_bump", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.MANAGE_BUMP, call, "Manage Bumps")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.edit_age_community = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Players", "edit_age_community", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.EDIT_AGE_COMMUNITY, call, "Edit Players Age Category and Community")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.edit_jersey = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Players", "edit_jersey", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.EDIT_JERSEY, call, "Edit Players Jersey")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.get_tax_receipt = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Players", "get_tax_receipt", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.GET_TAX_RECEIPT, call, "Get Tax Receipt")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.find_tax_receipts = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Players", "find_tax_receipts", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.FIND_TAX_RECEIPTS, call, "Find Tax Receipts")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.get_transfers = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Players", "get_transfers", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.OPTIMIZATIONS.GET_TRANSFERS, call, "Get Transfers")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.get_transfer_details = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Players", "get_transfer_details", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.OPTIMIZATIONS.GET_TRANSFER_DETAILS, call, "Get Transfer Details")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.get_age_bumps = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Players", "get_age_bumps", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.OPTIMIZATIONS.GET_AGE_BUMPS, call, "Get Age Bumps")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.reports = {};
    exports.reports.general = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Players", "reports.general", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REPORTS.GENERAL, call, "Reports: General")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.reports.on_teams = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Players", "on_teams", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REPORTS.ON_TEAMS, call, "Report: Players on Teams")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.reports.duplicates = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Players", "reports.duplicates", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REPORTS.DUPLICATES, call, "Report: Duplicate Player Registrations")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };


    exports.reports.incomplete_registrations = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Players", "reports.incomplete_registrations", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REPORTS.INCOMPLETE_REGISTRATIONS, call, "Report: Incomplete Player Registrations")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.reports.credit_card_payments = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Players", "reports.credit_card_payments", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REPORTS.CREDIT_CARD_PAYMENTS, call, "Reports: Credit Card Payments")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.reports.all_transactions = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Players", "reports.all_transactions", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REPORTS.ALL_TRANSACTIONS, call, "Reports: All Transactions")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.reports.discipline = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Players", "reports.discipline", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REPORTS.DISCIPLINE, call, "Reports: Discipline")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.reports.returning = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Players", "reports.returning", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REPORTS.RETURNING, call, "Reports: Returning")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.reports.demographics = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Players", "reports.demographics", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REPORTS.DEMOGRAPHICS, call, "Reports: Demographics")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.reports.granular_demographics = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Players", "reports.granular_demographics", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REPORTS.GRANULAR_DEMOGRAPHICS, call, "Reports: Granular Demographics")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.reports.asa = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Players", "reports.asa", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REPORTS.ASA, call, "Reports: ASA Players")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.reports.unsigned_sar_waivers = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Players", "reports.unsigned_sar_waivers", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REPORTS.UNSIGNED_SAR_WAIVERS, call, "Reports: Players Unsigned SAR Waivers")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.manage_players__get_players = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Players", "manage_players__get_players", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.OPTIMIZATIONS.MANAGE_PLAYERS__GET_PLAYERS, call, "Manage Players: Get Players")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.edit_roster__get_unassigned_players = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Players", "edit_roster__get_unassigned_players", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.OPTIMIZATIONS.EDIT_ROSTER__GET_UNASSIGNED_PLAYERS, call, "Edit Roster: Get Unassigned Players")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.player_registration_details__get_age_community = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Players", "player_registration_details__get_age_community", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.OPTIMIZATIONS.PLAYER_REGISTRATION_DETAILS__GET_AGE_COMMUNITY, call, "Player Registration Details: Get Age Category and Community")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.player_registration_details__get_available_ju_ac = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Players", "player_registration_details__get_available_ju_ac", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.OPTIMIZATIONS.PLAYER_REGISTRATION_DETAILS__GET_JU_AC, call, "Player Registration Details: Get Jurisdictions and Age Categories")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.player_registration_details__get_available_dv = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Players", "player_registration_details__get_available_dv", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.OPTIMIZATIONS.PLAYER_REGISTRATION_DETAILS__GET_DV, call, "Player Registration Details: Get Programs")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.edit_player__get_player_data = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Players", "edit_player__get_player_data", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.OPTIMIZATIONS.EDIT_PLAYER__GET_PLAYER_DATA, call, "Edit Player: Get Player Data")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };
})(typeof exports === 'undefined' ? this['Players'] = {} : exports);