(function (exports) {
    "use strict";

    exports.URL = {};
    exports.URL.CREATE = "/operations/organizational-management/teams/FN-OMT-001_create-team/FN-OMT-001_create-team.php";
    exports.URL.FIND = "/operations/organizational-management/teams/FN-OMT-003_find-team/FN-OMT-003_find-team.php";
    exports.URL.EDIT = "/operations/organizational-management/teams/FN-OMT-005_edit-team-details/FN-OMT-005_edit-team-details.php";
    exports.URL.EDIT_ROSTER_VISIBILITY = "/operations/organizational-management/teams/FN-OMT-011_edit-roster-visibility/FN-OMT-011_edit-roster-visibility.php";
    exports.URL.REMOVE = "/operations/organizational-management/teams/FN-OMT-006_delete-team/FN-OMT-006_delete-team.php";

    exports.URL.ADD_PLAYER = "/operations/organizational-management/teams/FN-OMT-009_add-player/FN-OMT-009_add-player.php";
    exports.URL.REMOVE_PLAYER = "/operations/organizational-management/teams/FN-OMT-010_remove-player/FN-OMT-010_remove-player.php";
    exports.URL.ADD_TEAM_OFFICIAL = "/operations/organizational-management/teams/FN-OMT-007_add-team-officials/FN-OMT-007_add-team-officials.php";
    exports.URL.REMOVE_TEAM_OFFICIAL = "/operations/organizational-management/teams/FN-OMT-008_remove-team-officials/FN-OMT-008_remove-team-officials.php";

    exports.URL.DOWNLOAD_ROSTER = "/operations/organizational-management/teams/FN-OMT-013_download-roster/FN-OMT-013_download-roster.php";
    exports.URL.VIEW_ROSTER = "/operations/organizational-management/teams/FN-OMT-014_view-roster/FN-OMT-014_view-roster.php";
    exports.URL.VIEW_SUSPENSIONS = "/operations/schedule-presentation/FN-SPR-007_view-suspensions/FN-SPR-007_view-suspensions.php";
    exports.URL.VIEW_CARDS = "/operations/schedule-presentation/FN-SPR-008_view-cards/FN-SPR-008_view-cards.php";
    exports.URL.DOWNLOAD_ID_CARDS = "/operations/organizational-management/teams/FN-OMT-017_download-team-id-cards/FN-OMT-017_download-team-id-cards.php";

    exports.URL.OPTIMIZATIONS = {};
    exports.URL.OPTIMIZATIONS.GET_TEAMS = "/operations/optimizations/manage-teams/get-teams/get-teams.php";
    exports.URL.OPTIMIZATIONS.EDIT_ROSTER__GET_ALL_DATA = '/operations/optimizations/edit-roster/get-all-data/get-all-data.php';
    exports.URL.OPTIMIZATIONS.EDIT_ROSTER__GET_TEAM_DEFAULTS = '/operations/optimizations/edit-roster/get-team-defaults/get-team-defaults.php';

    exports.URL.REPORTS = {};
    exports.URL.REPORTS.GENERAL = "/operations/organizational-management/teams/FN-OMT-015_general-report/FN-OMT-015_general-report.php";
    exports.URL.REPORTS.OVERSIZED = "/operations/organizational-management/teams/FN-OMT-016_oversized-report/FN-OMT-016_oversized-report.php";
    exports.URL.REPORTS.HOME_FIELDS = "/operations/reporting/pre-defined-reports/FN-PDR-009_team-home-fields/FN-PDR-009_team-home-fields.php";
    exports.URL.REPORTS.UNSIGNED_SAR_WAIVERS = "/operations/reporting/pre-defined-reports/FN-PDR-016_team-unsigned-sar-waivers/FN-PDR-016_team-unsigned-sar-waivers.php";

    exports.create = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Teams", "create", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.CREATE, call, "Create Teams")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.find = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Teams", "find", config);
        }

        var call = Security.wrapData(data);
        var loadConfig = {
            name: "Find Teams",
            url: exports.URL.FIND,
            callArrayName: "team-ids",
            responseArrayName: "teams",
            successFunc: config.successFunc,
            failureFunc: config.failureFunc,
            delayFunc: config.delayFunc
        };

        if (_.isUndefined(call.data["team-ids"])) Utilities.loadAll(call, loadConfig);
        else Utilities.loadSet(call, loadConfig);
    };

    exports.view = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Teams", "view", config);
        }

        var newConfig = {
            successFunc: (function (config) {
                return function (err, parameters, url, id, textStatus, jqXHR) {
                    parameters.data = parameters.data.teams[0];
                    config.successFunc(err, parameters, url, id, textStatus, jqXHR);
                }
            }(config)),
            failureFunc: config.failureFunc,
            delayFunc: config.delayFunc
        };

        exports.find({
            "team-ids": [data["team-id"]],
            "offset": 0,
            "limit": 100
        }, config);
    };

    exports.edit = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Teams", "edit", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.EDIT, call, "Edit Teams")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.edit_roster_visibility = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Teams", "edit_roster_visibility", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.EDIT_ROSTER_VISIBILITY, call, "Edit Teams")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.remove = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Teams", "remove", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REMOVE, call, "Remove Teams")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.add_player = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Teams", "add_player", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.ADD_PLAYER, call, "Add Player to Team")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.remove_player = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Teams", "remove_player", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REMOVE_PLAYER, call, "Remove Player from Team")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.add_team_official = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Teams", "add_team_official", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.ADD_TEAM_OFFICIAL, call, "Add Team Official to Team")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.remove_team_official = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Teams", "remove_team_official", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REMOVE_TEAM_OFFICIAL, call, "Remove Team Official from Team")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.download_roster = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Teams", "download_roster", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.DOWNLOAD_ROSTER, call, "Download Roster")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.view_roster = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Teams", "view_roster", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.VIEW_ROSTER, call, "View Roster")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.view_suspensions = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Teams", "view_suspensions", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.VIEW_SUSPENSIONS, call, "View Suspensions")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.view_cards = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Teams", "view_cards", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.VIEW_CARDS, call, "View Cards")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.download_id_cards = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Teams", "download_id_cards", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.DOWNLOAD_ID_CARDS, call, "Download ID Cards")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.optimizations__get_teams = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Teams", "optimizations__get_teams", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.OPTIMIZATIONS.GET_TEAMS, call, "Optimizations: Get Teams")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.optimizations__edit_roster__get_all_data = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Teams", "optimizations__edit_roster__get_all_data", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.OPTIMIZATIONS.EDIT_ROSTER__GET_ALL_DATA, call, "Optimizations: Edit Roster; Get All Data")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.optimizations__edit_roster__get_team_defaults = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Teams", "optimizations__edit_roster__get_team_defaults", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.OPTIMIZATIONS.EDIT_ROSTER__GET_TEAM_DEFAULTS, call, "Optimizations: Edit Roster; Get Team Defaults")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.reports = {};
    exports.reports.general = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Teams", "reports.general", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REPORTS.GENERAL, call, "Reports: General")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.reports.oversized = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Teams", "reports.oversized", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REPORTS.OVERSIZED, call, "Reports: Oversized")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.reports.home_fields = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Teams", "reports.home_fields", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REPORTS.HOME_FIELDS, call, "Reports: Home Fields")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.reports.unsigned_sar_waivers = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Teams", "reports.unsigned_sar_waivers", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REPORTS.UNSIGNED_SAR_WAIVERS, call, "Reports: Teams Unsigned SAR Waivers")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };
})(typeof exports === 'undefined' ? this['Teams'] = {} : exports);