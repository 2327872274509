(function (exports) {
    "use strict";

    exports.URL = {};
    exports.URL.CREATE = "/operations/person-management/player-and-coach-discipline/FN-END-001_create-disciplinary-card/FN-END-001_create-disciplinary-card.php";
    exports.URL.VIEW = "/operations/person-management/player-and-coach-discipline/FN-END-002_view-disciplinary-card-details/FN-END-002_view-disciplinary-card-details.php";
    exports.URL.EDIT = "/operations/person-management/player-and-coach-discipline/FN-END-003_edit-disciplinary-card-details/FN-END-003_edit-disciplinary-card-details.php";
    exports.URL.REMOVE = "/operations/person-management/player-and-coach-discipline/FN-END-004_delete-disciplinary-card/FN-END-004_delete-disciplinary-card.php";

    exports.create = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("DisciplinaryCards", "create", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.CREATE, call, "Create Disciplinary Card")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.view = function (data, config) {
        return config.failureFunc("DisciplinaryCards.view not implemented");
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("DisciplinaryCards", "view", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.VIEW, call, "View Disciplinary Card")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.edit = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("DisciplinaryCards", "edit", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.EDIT, call, "Edit Disciplinary Card")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.remove = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("DisciplinaryCards", "remove", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REMOVE, call, "Remove Disciplinary Card")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };
})(typeof exports === 'undefined' ? this['DisciplinaryCards'] = {} : exports);