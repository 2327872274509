(function (exports) {
    "use strict";

    exports.URL = {};
    exports.URL.CREATE = "/operations/person-management/address-management/FN-ASM-001_create-address/FN-ASM-001_create-address.php";
    exports.URL.FIND = "/operations/person-management/address-management/FN-ASM-005_find-address/FN-ASM-005_find-address.php";
    exports.URL.VIEW = "/operations/person-management/address-management/FN-ASM-002_view-address-details/FN-ASM-002_view-address-details.php";
    exports.URL.EDIT = "/operations/person-management/address-management/FN-ASM-003_edit-address-details/FN-ASM-003_edit-address-details.php";
    exports.URL.REMOVE = "/operations/person-management/address-management/FN-ASM-004_delete-address/FN-ASM-004_delete-address.php";


    exports.create = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Addresses", "create", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.CREATE, call, "Create Addresses")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.find = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Addresses", "find", config);
        }

        var call = Security.wrapData(data);
        var loadConfig = {
            name: "Find Addresses",
            url: exports.URL.FIND,
            callArrayName: "address-ids",
            responseArrayName: "addresses",
            successFunc: config.successFunc,
            failureFunc: config.failureFunc,
            delayFunc: config.delayFunc
        };

        if (_.isUndefined(call.data["address-ids"])) Utilities.loadAll(call, loadConfig);
        else Utilities.loadSet(call, loadConfig);
    };

    exports.view = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Addresses", "view", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.VIEW, call, "View Addresses")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.edit = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Addresses", "edit", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.EDIT, call, "Edit Addresses")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.remove = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Addresses", "remove", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REMOVE, call, "Remove Addresses")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };
})(typeof exports === 'undefined' ? this['Addresses'] = {} : exports);