(function (exports) {
    "use strict";

    exports.URL = {};
    exports.URL.CREATE = "/operations/organizational-management/age-categories/FN-OMA-002_create-age-category/FN-OMA-002_create-age-category.php";
    exports.URL.FIND = "/operations/organizational-management/age-categories/FN-OMA-001_find-age-category/FN-OMA-001_find-age-category.php";
    exports.URL.VIEW = "/operations/organizational-management/age-categories/FN-OMA-003_view-age-category-details/FN-OMA-003_view-age-category-details.php";
    exports.URL.EDIT = "/operations/organizational-management/age-categories/FN-OMA-005_edit-age-category-details/FN-OMA-005_edit-age-category-details.php";
    exports.URL.REMOVE = "/operations/organizational-management/age-categories/FN-OMA-004_delete-age-category/FN-OMA-004_delete-age-category.php";
    
    exports.URL.FIND_BY_SEJU = "/operations/person-management/player-management/FN-ENP-041_find-allowed-age-categories/FN-ENP-041_find-allowed-age-categories.php";


    exports.create = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("AgeCategories", "create", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.CREATE, call, "Create Age Categories")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.find = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("AgeCategories", "find", config);
        }

        var call = Security.wrapData(data);
        var loadConfig = {
            name: "Find Age Category",
            url: exports.URL.FIND,
            callArrayName: "age-category-ids",
            responseArrayName: "age-categories",
            successFunc: config.successFunc,
            failureFunc: config.failureFunc,
            delayFunc: config.delayFunc
        };

        if (_.isUndefined(call.data["age-categories-ids"])) Utilities.loadAll(call, loadConfig);
        else Utilities.loadSet(call, loadConfig);
    };

    exports.view = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("AgeCategories", "view", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.VIEW, call, "View Age Categories")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.edit = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("AgeCategories", "edit", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.EDIT, call, "Edit Age Categories")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.remove = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("AgeCategories", "remove", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REMOVE, call, "Remove Age Categories")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };
    
    exports.find_by_seju = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("AgeCategories", "find_by_seju", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.FIND_BY_SEJU, call, "Find Age Categories by Season and Jurisdiction")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };
})(typeof exports === 'undefined' ? this['AgeCategories'] = {} : exports);