(function (exports) {
    "use strict";

    exports.URL = {};
    exports.URL.CREATE = "/operations/organizational-management/tiers/FN-OMI-001_create-tier/FN-OMI-001_create-tier.php";
    exports.URL.EDIT = "/operations/organizational-management/tiers/FN-OMI-002_edit-tier/FN-OMI-002_edit-tier.php";
    exports.URL.REMOVE = "/operations/organizational-management/tiers/FN-OMI-003_delete-tier/FN-OMI-003_delete-tier.php";

    exports.URL.ADD_TEAM = "/operations/organizational-management/tiers/FN-OMI-004_add-team/FN-OMI-004_add-team.php";
    exports.URL.REMOVE_TEAM = "/operations/organizational-management/tiers/FN-OMI-005_remove-team/FN-OMI-005_remove-team.php";

    exports.URL.ADD_TEAMS = {};
    exports.URL.ADD_TEAMS.GET_TEAMS = "/operations/optimizations/manage-divisions/add-teams/get-teams/get-teams.php";

    exports.URL.CREATE_GAMES = "/operations/organizational-management/tiers/FN-OMI-006_create-games/FN-OMI-006_create-games.php";

    exports.URL.OPTIMIZATIONS = {};
    exports.URL.OPTIMIZATIONS.BULK_SCHEDULING = {};
    exports.URL.OPTIMIZATIONS.BULK_SCHEDULING.GET_TIERS = "/operations/optimizations/bulk-scheduling/get-tiers/get-tiers.php";

    exports.create = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Tiers", "create", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.CREATE, call, "Create Tier")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };


    exports.edit = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Tiers", "edit", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.EDIT, call, "Edit Tier")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.remove = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Tiers", "remove", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REMOVE, call, "Remove Tier")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.add_team = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Tiers", "add_team", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.ADD_TEAM, call, "Add Team Tier")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.remove_team = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Tiers", "remove_team", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REMOVE_TEAM, call, "Remove Team Tier")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.add_teams = {};
    exports.add_teams.get_teams = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Tiers", "add_teams.get_teams", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.ADD_TEAMS.GET_TEAMS, call, "Tier > Add Teams > Get Teams")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.create_games = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Tiers", "create_games", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.CREATE_GAMES, call, "Tiers > Create Games")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.optimizations = {};
    exports.optimizations.bulk_scheduling = {};
    exports.optimizations.bulk_scheduling.get_tiers = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Tiers", "optimizations.bulk_scheduling.get_tiers", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.OPTIMIZATIONS.BULK_SCHEDULING.GET_TIERS, call, "Tier > Bulk Scheduling > Get Tiers")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };
})(typeof exports === 'undefined' ? this['Tiers'] = {} : exports);