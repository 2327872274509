(function (exports) {
    "use strict";

    exports.URL = {};
    exports.URL.CREATE = "/operations/schedule-management/FN-SMN-010_add-preferred-time/FN-SMN-010_add-preferred-time.php";
    exports.URL.REMOVE = "/operations/schedule-management/FN-SMN-011_delete-preferred-time/FN-SMN-011_delete-preferred-time.php";


    exports.create = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("TimePreferences", "create", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.CREATE, call, "Create Time Preferences")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.remove = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("TimePreferences", "remove", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REMOVE, call, "Remove Time Preferences")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };
})(typeof exports === 'undefined' ? this['TimePreferences'] = {} : exports);