(function (exports) {
    "use strict";

    exports.URL = {};
    exports.URL.CREATE = "/operations/person-management/FN-ENM-029_become-administrator/FN-ENM-029_become-administrator.php";
    exports.URL.FIND = "";
    exports.URL.VIEW = "";
    exports.URL.EDIT = "";
    exports.URL.REMOVE = "/operations/security/FN-SEC-005_remove-role/FN-SEC-005_remove-role.php";

    exports.URL.OPTIMIZATIONS = {};
    exports.URL.OPTIMIZATIONS.GET_ADMINISTRATORS = "/operations/optimizations/manage-administrators/get-administrators/get-administrators.php";


    exports.create = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Administrators", "create", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.CREATE, call, "Create Administrator")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.find = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Administrators", "find", config);
        }

        throw "[Administrators.find]: not implemented";

        var call = Security.wrapData(data);
        var loadConfig = {
            name: "Find Administrators",
            url: exports.URL.FIND,
            callArrayName: "administrator-ids",
            responseArrayName: "administrators",
            successFunc: config.successFunc,
            failureFunc: config.failureFunc,
            delayFunc: config.delayFunc
        };

        if (_.isUndefined(call.data["administrator-ids"])) Utilities.loadAll(call, loadConfig);
        else Utilities.loadSet(call, loadConfig);
    };

    exports.view = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Administrators", "view", config);
        }

        throw "[Administrators.view]: not implemented";

        var call = Security.wrapData(data);
        server.request(exports.URL.VIEW, call, "View Administrator")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.edit = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Administrators", "edit", config);
        }

        throw "[Administrators.edit]: not implemented";

        var call = Security.wrapData(data);
        server.request(exports.URL.EDIT, call, "Edit Administrator")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.remove = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Administrators", "remove", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REMOVE, call, "Remove Administrator")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };


    exports.optimizations__get_administrators = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Administrators", "get_administrators", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.OPTIMIZATIONS.GET_ADMINISTRATORS, call, "Get Administrators")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };
})(typeof exports === 'undefined' ? this['Administrators'] = {} : exports);