(function (exports) {
    "use strict";

    exports.URL = {};
    exports.URL.CREATE = "/operations/organizational-management/venues/FN-OMV-001_create-venue/FN-OMV-001_create-venue.php";
    exports.URL.FIND = "/operations/organizational-management/venues/FN-OMV-003_find-venue/FN-OMV-003_find-venue.php";
    exports.URL.EDIT = "/operations/organizational-management/venues/FN-OMV-005_edit-venue-details/FN-OMV-005_edit-venue-details.php";
    exports.URL.REMOVE = "/operations/organizational-management/venues/FN-OMV-006_delete-venue/FN-OMV-006_delete-venue.php";

    exports.URL.VIEW_SCHEDULE = "/operations/schedule-management/FN-SMN-026_view-field-schedule/FN-SMN-026_view-field-schedule.php";

    exports.URL.OPTIMIZATIONS = {};
    exports.URL.OPTIMIZATIONS.MANAGE_VENUES__GET_VENUES = "/operations/optimizations/manage-venues/get-venues/get-venues.php";

    exports.create = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Venues", "create", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.CREATE, call, "Create Venue")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.find = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Venues", "find", config);
        }

        var call = Security.wrapData(data);
        var loadConfig = {
            name: "Find Venues",
            url: exports.URL.FIND,
            callArrayName: "venue-ids",
            responseArrayName: "venues",
            successFunc: config.successFunc,
            failureFunc: config.failureFunc,
            delayFunc: config.delayFunc
        };

        if (_.isUndefined(call.data["venue-ids"])) Utilities.loadAll(call, loadConfig);
        else Utilities.loadSet(call, loadConfig);
    };

    exports.edit = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Venues", "edit", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.EDIT, call, "Edit Venue")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.remove = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Venues", "remove", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REMOVE, call, "Remove Venue")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.view_schedule = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Venues", "view_schedule", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.VIEW_SCHEDULE, call, "View Venue Schedule")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.optimizations = {};

    exports.optimizations.manage_venues__get_venues = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Venues", "manage_venues__get_venues", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.OPTIMIZATIONS.MANAGE_VENUES__GET_VENUES, call, "Manage Venues: Get Venues")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };
})(typeof exports === 'undefined' ? this['Venues'] = {} : exports);