(function (exports) {
    'use strict';

    exports.URL = {};
    exports.URL.CREATE = "/operations/person-management/login-management/FN-ENL-012_create-login-account/FN-ENL-012_create-login-account.php";
    exports.URL.CREATE_AS_OPERATOR = "/operations/person-management/login-management/FN-ENL-002_create-account-as-operator/FN-ENL-002_create-account-as-operator.php";
    exports.URL.FIND = "/operations/person-management/login-management/FN-ENL-004_find-login-account/FN-ENL-004_find-login-account.php";
    exports.URL.VIEW = "/operations/person-management/login-management/FN-ENL-005_view-login-details/FN-ENL-005_view-login-details.php";
    exports.URL.EDIT = "/operations/person-management/login-management/FN-ENL-006_edit-login-details/FN-ENL-006_edit-login-details.php";
    exports.URL.RECOVER_PASSWORD = "/operations/person-management/login-management/FN-ENL-008_recover-password/FN-ENL-008_recover-password.php";
    exports.URL.REMOVE = "/operations/person-management/login-management/FN-ENL-011_delete-login-account/FN-ENL-011_delete-login-account.php";
    exports.URL.EMAIL_EXISTS = "/operations/person-management/login-management/FN-ENL-013_email-address-exists/FN-ENL-013_email-address-exists.php";
    exports.URL.GET_VALIDATION_TOKEN = "/operations/person-management/login-management/FN-ENL-014_view-validation-token/FN-ENL-014_view-validation-token.php";
    exports.URL.CHANGE_ACCOUNT_HOLDER = "/operations/person-management/login-management/FN-ENL-015_change-account-holder/FN-ENL-015_change-account-holder.php";
    exports.URL.MOVE_TO_NEW_ACCOUNT = "/operations/person-management/login-management/FN-ENL-016_move-to-new-account/FN-ENL-016_move-to-new-account.php";
    exports.URL.CHECK_ACCOUNT_COLLISION = "/operations/person-management/login-management/FN-ENL-017_check-account-collision/FN-ENL-017_check-account-collision.php";
    exports.URL.INITIATE_ACCOUNT_CHANGE = "/operations/person-management/login-management/FN-ENL-018_initiate-account-change/FN-ENL-018_initiate-account-change.php";
    exports.URL.CONFIRM_ACCOUNT_CHANGE = "/operations/person-management/login-management/FN-ENL-019_confirm-account-change/FN-ENL-019_confirm-account-change.php";
    exports.URL.VIEW_EMAIL_CHANGE_TOKEN = "/operations/person-management/login-management/FN-ENL-020_view-email-change-token/FN-ENL-020_view-email-change-token.php";

    exports.URL.LOCK_ACCOUNT = "/operations/security/FN-SEC-006_lock-account/FN-SEC-006_lock-account.php";
    exports.URL.UNLOCK_ACCOUNT = "/operations/security/FN-SEC-007_unlock-account/FN-SEC-007_unlock-account.php";

    exports.URL.REPORTS = {};
    exports.URL.REPORTS.UNSIGNED_SAR_WAIVERS = "/operations/reporting/pre-defined-reports/FN-PDR-017_account-unsigned-sar-waivers/FN-PDR-017_account-unsigned-sar-waivers.php";

    exports.create = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("LoginRoles", "create", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.CREATE, call, "Create Login Roles")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.create_as_operator = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("LoginRoles", "create_as_operator", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.CREATE_AS_OPERATOR, call, "Create Login Roles (as Operator)")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.find = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("LoginRoles", "find", config);
        }


        var call = Security.wrapData(data);
        server.request(exports.URL.FIND, call, "Find Login Roles")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.view = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("LoginRoles", "view", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.VIEW, call, "View Login Roles")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.edit = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("LoginRoles", "edit", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.EDIT, call, "Edit Login Roles")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.recover_password = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("LoginRoles", "recover_password", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.RECOVER_PASSWORD, call, "Recover password")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.remove = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("LoginRoles", "remove", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REMOVE, call, "Remove Login Roles")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.change_account_holder = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("LoginRoles", "change_account_holder", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.CHANGE_ACCOUNT_HOLDER, call, "Change Login Role Holder")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.move_to_new_account = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("LoginRoles", "move_to_new_account", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.MOVE_TO_NEW_ACCOUNT, call, "Move Person to new account")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.check_account_collision = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("LoginRoles", "check_account_collision", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.CHECK_ACCOUNT_COLLISION, call, "Check Account Collision")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };


    exports.email_exists = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("LoginRoles", "email_exists", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.EMAIL_EXISTS, call, "Email exists?")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.get_validation_token = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("LoginRoles", "get_validation_token", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.GET_VALIDATION_TOKEN, call, "Get Validation Token")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.initiate_account_change = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("LoginRoles", "initiate_account_change", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.INITIATE_ACCOUNT_CHANGE, call, "Initiate Account Change")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.confirm_account_change = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("LoginRoles", "confirm_account_change", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.CONFIRM_ACCOUNT_CHANGE, call, "Confirm Account Change")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.view_email_change_token = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("LoginRoles", "view_email_change_token", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.VIEW_EMAIL_CHANGE_TOKEN, call, "View Email Change Token")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.lock_account = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("LoginRoles", "lock_account", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.LOCK_ACCOUNT, call, "Lock Account")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.unlock_account = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("LoginRoles", "unlock_account", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.UNLOCK_ACCOUNT, call, "Unlock Account")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.reports = {};
    exports.reports.unsigned_sar_waivers = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("LoginRoles", "reports.unsigned_sar_waivers", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REPORTS.UNSIGNED_SAR_WAIVERS, call, "Reports: LoginRoles Unsigned SAR Waivers")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    //-----------------------------------------------------------------------------------------------------------------

    // This is exported because it is directly used by external functions
    // TODO - Extract the lightbox as its  own function because it is used so much.  Look into pre-compiled Handlebars
    // templates.
    exports.deleteLoginAccount = function (loginAccountId) {
        var fancyboxHtml = '';
        fancyboxHtml += '<div style="vertical-align: middle; text-align: center; width: 640px;">';
        fancyboxHtml += '<h1>Are you sure you want to delete this login account? Please Confirm.</h1>';
        fancyboxHtml += '<p>';
        fancyboxHtml += '<input type="button" id="btnYes" name="btnYes" value="Yes, I Confirm" onclick="deleteLoginAccountConfirmation(' + loginAccountId + ');" class="btn btn-primary" />';
        fancyboxHtml += '&nbsp;&nbsp;';
        fancyboxHtml += '<input type="button" id="btnNo" name="btnNo" value="No, Close Window" onclick="$.fancybox.close()" class="btn btn-default" />';
        fancyboxHtml += '</p>';
        fancyboxHtml += '</div>';
        $.fancybox(
            '' + fancyboxHtml,
            {
                'autoScale': false,
                'width': 640,
                'height': 360,
                'transitionIn': 'fade',
                'transitionOut': 'none',
                'overlayOpacity': '0.7',
                'overlayColor': '#000000',
                'centerOnScroll': 'true',
                'scrolling': 'no'
            }
        );
    };

    // This is not because it is only used by other functions within this module.  We could put it inside of
    // deleteLoginAccount if we wanted because it
    function deleteLoginAccountConfirmation(loginAccountId) {
        displayOverlay();

        function success(err, parameters, resource, id, textStatus, jqXHR) {
            // TODO - consider reloading the table using JS
            redirect('manage-logins.php?delete=true');
        }

        function failure(err, parameters, resource, id, textStatus, jqXHR) {
            // TODO - handle error
            displayMessage('error', 'Error deleting login account.');
        }

        function delay(err, parameters, resource, id, textStatus, jqXHR) {
            // TODO - handle delay
            displayMessage('warning', 'Database read/write is slow.');
        }

        var parameters = {
            "session-id": Storage.get("session-id"),
            "role": Storage.get("role"),
            "data": {
                "login-account-id": loginAccountId
            }
        };
        server.request(DELETE_LOGIN_ACCOUNT_URL, parameters, "DELETE LOGIN ACCOUNT")
            .success(success)
            .failure(failure)
            .delay(delay)
            .run();
    };
})(typeof exports === 'undefined' ? this['LoginRoles'] = {} : exports);