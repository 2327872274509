(function (exports) {
    "use strict";

    exports.URL = {};
    exports.URL.RUN = "/operations/reporting/FN-REP-006_run-report/FN-REP-006_run-report.php";
    exports.URL.GET_STATISTICS = "/operations/reporting/FN-REP-009_get-statistics/FN-REP-009_get-statistics.php";
    exports.URL.GET_YEAR_END_SUMMARY_STATISTICS = "/operations/reporting/FN-REP-010_get-year-end-summary-statistics/FN-REP-010_get-year-end-summary-statistics.php";

    exports.URL.TRANSFERS = '/operations/reporting/pre-defined-reports/FN-PDR-001_transfers/FN-PDR-001_transfers.php';

    exports.URL.OVERLAPPING_GAMES = '/operations/reporting/pre-defined-reports/FN-PDR-004_overlapping-games/FN-PDR-004_overlapping-games.php';
    exports.URL.OVERLAPPING_AVAILABILITIES = '/operations/reporting/pre-defined-reports/FN-PDR-005_overlapping-availabilities/FN-PDR-005_overlapping-availabilities.php';

    exports.URL.ALL_FEES = '/operations/reporting/pre-defined-reports/FN-PDR-010_all-fees/FN-PDR-010_all-fees.php';

    exports.URL.ACCOUNT_HISTORY = '/operations/reporting/pre-defined-reports/FN-PDR-011_account-history/FN-PDR-011_account-history.php';

    exports.URL.TEAM_OFFICIALS_WITHOUT_EMAILS = '/operations/reporting/pre-defined-reports/FN-PDR-012_team_officials_without_emails/FN-PDR-012_team_officials_without_emails.php';
    exports.URL.ACCOUNTS_WITHOUT_EMAILS = '/operations/reporting/pre-defined-reports/FN-PDR-013_accounts-without-emails/FN-PDR-013_accounts-without-emails.php';

    exports.run_query = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Reports", "run_query", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.RUN, call, "Run Query")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.run_query_all = function (data, config) {
        data = JSON.parse(JSON.stringify(data));

        if (_.isUndefined(data.query.limit)) data.query.limit = 100;
        if (_.isUndefined(data.query.offset)) data.query.offset = 0;

        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Reports", "run_query", config);
        }

        var rows = [];

        var success = function (err, response) {
            rows = rows.concat(response.data);
            call.data.query.offset += response.data.length;

            if (response.data.length === data.query.limit) {
                server.request(exports.URL.RUN, call, "Run Query")
                    .success(success)
                    .failure(config.failureFunc)
                    .delay(config.delayFunc)
                    .run();
            } else {
                response.data = rows;
                config.successFunc(null, response);
            }
        };

        var call = Security.wrapData(data);
        server.request(exports.URL.RUN, call, "Run Query")
            .success(success)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.get_statistics = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Reports", "get_statistics", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.GET_STATISTICS, call, "Get Statistics")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.get_year_end_summary_statistics = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Reports", "get_year_end_summary_statistics", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.GET_YEAR_END_SUMMARY_STATISTICS, call, "Get Year End Summary Statistics")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.transfers = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Reports", "transfers", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.TRANSFERS, call, "Get Transfers")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.overlapping_games = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Reports", "overlapping_games", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.OVERLAPPING_GAMES, call, "Get Overlapping Games")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.overlapping_availabilities = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Reports", "overlapping_availabilities", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.OVERLAPPING_AVAILABILITIES, call, "Get Overlapping Availabilities")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.all_fees = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Reports", "all_fees", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.ALL_FEES, call, "Get All Fees")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.account_history = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Reports", "account_history", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.ACCOUNT_HISTORY, call, "Get Account History")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.team_officials_without_emails = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Reports", "team_officials_without_emails", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.TEAM_OFFICIALS_WITHOUT_EMAILS, call, "Get Team Officials Without Emails")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.accounts_without_emails = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Reports", "accounts_without_emails", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.ACCOUNTS_WITHOUT_EMAILS, call, "Get Accounts Without Emails")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };
})(typeof exports === 'undefined' ? this['Reports'] = {} : exports);