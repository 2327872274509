(function (exports) {
    "use strict";

    exports.URL = {};
    exports.URL.CREATE = "/operations/organizational-management/switches/FN-OML-001_create-switch/FN-OML-001_create-switch.php";
    exports.URL.FIND = "/operations/organizational-management/switches/FN-OML-003_view-switches/FN-OML-003_view-switches.php";
    exports.URL.VIEW = "/operations/organizational-management/switches/FN-OML-003_view-switches/FN-OML-003_view-switches.php";
    exports.URL.EDIT = "/operations/organizational-management/switches/FN-OML-004_edit-switch/FN-OML-004_edit-switch.php";
    exports.URL.REMOVE = "/operations/organizational-management/switches/FN-OML-005_delete-switch/FN-OML-005_delete-switch.php";

    exports.URL.CURRENT_VALUES = "/operations/organizational-management/switches/FN-OML-006_current-values/FN-OML-006_current-values.php";

    exports.create = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Switches", "create", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.CREATE, call, "Create Switches")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.find = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Switches", "find", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.FIND, call, "Find Switches")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.view = exports.find;

    exports.edit = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Switches", "edit", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.EDIT, call, "Edit Switches")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.remove = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Switches", "remove", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REMOVE, call, "Remove Switches")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.current_values = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Switches", "current_values", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.CURRENT_VALUES, call, "Get Switches' Current Values")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };
})(typeof exports === 'undefined' ? this['Switches'] = {} : exports);