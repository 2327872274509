(function (exports) {
    "use strict";

    exports.URL = {};
    exports.URL.VIEW = "/operations/schedule-presentation/FN-SPR-002_view-schedule/FN-SPR-002_view-schedule.php";
    exports.URL.VIEW_STANDINGS = "/operations/schedule-presentation/FN-SPR-004_view-division-standings/FN-SPR-004_view-division-standings.php";

    exports.URL.UPLOAD_AVAILABILITIES = "/operations/schedule-management/FN-SMN-027_upload-availabilities/FN-SMN-027_upload-availabilities.php";
    
    exports.URL.GET_MASTER_SCHEDULE = "/operations/schedule-management/FN-SMN-025_get-master-list/FN-SMN-025_get-master-list.php";

    exports.view = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Schedules", "view", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.VIEW, call, "View Schedule")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.view_standings = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Schedules", "view_standings", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.VIEW_STANDINGS, call, "View Division Standings")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.upload_availabilities = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Schedules", "upload_availabilities", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.UPLOAD_AVAILABILITIES, call, "Upload Availabilities", {timeout: 600000}) // 10 minute timeout
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.get_master_schedule = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Schedules", "get_master_schedule", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.GET_MASTER_SCHEDULE, call, "Get Master Schedule")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };
})(typeof exports === 'undefined' ? this['Schedules'] = {} : exports);