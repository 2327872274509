(function (exports) {
    "use strict";

    exports.loadAll = function (call, config) {
        call = JSON.parse(JSON.stringify(call));
        if (_.isUndefined(config.url)) throw "Utilities.loadAll: undefined url";
        if (_.isUndefined(config.successFunc)) throw "Utilities.loadAll: undefined successFunc";
        if (_.isUndefined(config.failureFunc)) throw "Utilities.loadAll: undefined failureFunc";
        if (_.isUndefined(config.delayFunc)) throw "Utilities.loadAll: undefined delayFunc";
        if (_.isUndefined(config.responseArrayName)) throw "Utilities.loadAll: undefined responseArrayName";

        if (_.isUndefined(config.name)) config.name = config.url;
        if (_.isUndefined(call.data.limit)) call.data.limit = 100;
        if (_.isUndefined(call.data.offset)) call.data.offset = 0;

        var data = [];

        var makeCall = function() {
            server.request(config.url, call, config.name)
                .success(success)
                .failure(config.failureFunc)
                .delay(config.delayFunc)
                .run();
        };

        var success = function (err, response, url, id, textStatus, jqXHR) {
            // Accumulate data
            data = data.concat(response.data[config.responseArrayName]);

            // We've run out of data; return the result
            if (response.data[config.responseArrayName].length < call.data.limit) {
                response.data[config.responseArrayName] = data;
                config.successFunc(err, response, url, id, textStatus, jqXHR);
            // We still have more to go
            } else {
                // Fetch the next batch of data
                call.data.offset += call.data.limit;
                makeCall();
            }
        };

        makeCall();
    };

    exports.loadSet = function (call, config) {
        call = _.clone(call);

        call = JSON.parse(JSON.stringify(call));
        if (_.isUndefined(config.url)) throw "Utilities.loadSet: undefined url";
        if (_.isUndefined(config.successFunc)) throw "Utilities.loadSet: undefined successFunc";
        if (_.isUndefined(config.failureFunc)) throw "Utilities.loadSet: undefined failureFunc";
        if (_.isUndefined(config.delayFunc)) throw "Utilities.loadSet: undefined delayFunc";
        if (_.isUndefined(config.callArrayName)) throw "Utilities.loadSet: undefined callArrayName";
        if (_.isUndefined(config.responseArrayName)) throw "Utilities.loadSet: undefined responseArrayName";

        if (_.isUndefined(config.name)) config.name = config.url;
        if (_.isUndefined(call.data.limit)) call.data.limit = 100;
        if (_.isUndefined(call.data.offset)) call.data.offset = 0;

        var data = [];
        var ids = call.data[config.callArrayName];
        call.data[config.callArrayName] = ids.slice(0, call.data.limit);
        ids = ids.slice(call.data.limit);

        var makeCall = function() {
            server.request(config.url, call, config.name)
                .success(success)
                .failure(config.failureFunc)
                .delay(config.delayFunc)
                .run();
        };

        var success = function (err, response, url, id, textStatus, jqXHR) {
            // Accumulate data
            data = data.concat(response.data[config.responseArrayName]);

            call.data[config.callArrayName] = ids.slice(0, call.data.limit);
            ids = ids.slice(call.data.limit);

            if (ids.length === 0) {
                // We've run out of data; return the result
                response.data[config.responseArrayName] = data;
                config.successFunc(err, response, url, id, textStatus, jqXHR);
            } else {
                // We still have more to go
                // Fetch the next batch of data
                makeCall();
            }
        };

        makeCall();
    };
})(typeof exports === 'undefined' ? this['Utilities'] = {} : exports);