(function (exports) {
    "use strict";

    exports.URL = {};
    exports.URL.CREATE = "/operations/organizational-management/seasons/FN-OMS-001_create-season/FN-OMS-001_create-season.php";
    exports.URL.FIND = "/operations/organizational-management/seasons/FN-OMS-005_list-seasons/FN-OMS-005_list-seasons.php";
    exports.URL.EDIT = "/operations/organizational-management/seasons/FN-OMS-003_edit-season-details/FN-OMS-003_edit-season-details.php";
    exports.URL.REMOVE = "/operations/organizational-management/seasons/FN-OMS-004_delete-season/FN-OMS-004_delete-season.php";
    exports.URL.CLONE = "/operations/organizational-management/seasons/FN-OMS-006_clone-season/FN-OMS-006_clone-season.php";


    exports.create = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Seasons", "create", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.CREATE, call, "Create Seasons")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.find = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Seasons", "find", config);
        }

        var call = Security.wrapData(data);
        var loadConfig = {
            name: "Find Seasons",
            url: exports.URL.FIND,
            callArrayName: "season-ids",
            responseArrayName: "seasons",
            successFunc: config.successFunc,
            failureFunc: config.failureFunc,
            delayFunc: config.delayFunc
        };

        if (_.isUndefined(call.data["season-ids"])) Utilities.loadAll(call, loadConfig);
        else Utilities.loadSet(call, loadConfig);
    };

    exports.view = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Seasons", "view", config);
        }

        var newConfig = {
            successFunc: (function (config) {
                return function (err, parameters, url, id, textStatus, jqXHR) {
                    parameters.data = parameters.data.seasons[0];
                    config.successFunc(err, parameters, url, id, textStatus, jqXHR);
                }
            }(config)),
            failureFunc: config.failureFunc,
            delayFunc: config.delayFunc
        };

        var newData = _.clone(data);
        delete newData["season-id"];
        newData["season-ids"] = [data["season-id"]];
        newData.offset = 0;
        newData.limit = 1;

        exports.find(newData, newConfig);
    };

    exports.edit = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Seasons", "edit", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.EDIT, call, "Edit Seasons")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.remove = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Seasons", "remove", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REMOVE, call, "Remove Seasons")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };
    
    exports.clone = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Seasons", "clone", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.CLONE, call, "Clone Seasons")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };
})(typeof exports === 'undefined' ? this['Seasons'] = {} : exports);