(function (exports) {
    "use strict";

    exports.URL = {};
    exports.URL.CREATE = "/operations/organizational-management/waivers/FN-OMW-001_create-waiver/FN-OMW-001_create-waiver.php";
    exports.URL.FIND = "/operations/organizational-management/waivers/FN-OMW-002_find-waivers/FN-OMW-002_find-waivers.php";
    exports.URL.EDIT = "/operations/organizational-management/waivers/FN-OMW-003_edit-waiver/FN-OMW-003_edit-waiver.php";
    exports.URL.REMOVE = "/operations/organizational-management/waivers/FN-OMW-004_delete-waiver/FN-OMW-004_delete-waiver.php";
    exports.URL.DOWNLOAD = "/operations/organizational-management/waivers/FN-OMW-005_download-waivers/FN-OMW-005_download-waivers.php";
    exports.URL.SIGN = "/operations/organizational-management/waivers/FN-OMW-006_sign-waiver/FN-OMW-006_sign-waiver.php";

    exports.create = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Waivers", "create", config);
        }

        var call = Security.wrapData(data);

        console.log(call);
        server.request(exports.URL.CREATE, call, "Create Waiver")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.find = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Waivers", "find", config);
        }

        var call = Security.wrapData(data);
        var loadConfig = {
            name: "Find Waivers",
            url: exports.URL.FIND,
            callArrayName: "waiver-ids",
            responseArrayName: "waivers",
            successFunc: config.successFunc,
            failureFunc: config.failureFunc,
            delayFunc: config.delayFunc
        };

        if (_.isUndefined(call.data["waiver-ids"])) Utilities.loadAll(call, loadConfig);
        else Utilities.loadSet(call, loadConfig);
    };

    exports.view = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Waivers", "view", config);
        }

        var newConfig = {
            successFunc: (function (config) {
                return function (err, parameters, url, id, textStatus, jqXHR) {
                    parameters.data = parameters.data.waivers[0];
                    config.successFunc(err, parameters, url, id, textStatus, jqXHR);
                }
            }(config)),
            failureFunc: config.failureFunc,
            delayFunc: config.delayFunc
        };

        exports.find({
            "waiver-ids": [data["waiver-id"]],
            "offset": 0,
            "limit": 100
        }, newConfig);
    };

    exports.edit = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Waivers", "edit", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.EDIT, call, "Edit Waiver")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.remove = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Waivers", "remove", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REMOVE, call, "Remove Waiver")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.sign = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Waivers", "sign", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.SIGN, call, "Sign Waiver")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };
})(typeof exports === 'undefined' ? this['Waivers'] = {} : exports);