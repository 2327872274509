(function (exports) {
    "use strict";

    exports.URL = {};
    exports.URL.CHANGE_PASSWORD = "/operations/person-management/login-management/FN-ENL-007_change-password/FN-ENL-007_change-password.php";
    exports.URL.SIGN_IN = "/operations/security/FN-SEC-009_sign-in/FN-SEC-009_sign-in.php";
    exports.URL.SIGN_OUT = "/operations/security/FN-SEC-010_sign-out/FN-SEC-010_sign-out.php";
    exports.URL.VIEW_USER_ROLES = "/operations/security/FN-SEC-002_view-roles/FN-SEC-002_view-roles.php";

    /* sign in button click */
    exports.processSignIn = function (emailAddress, password, rememberMe, callback) {
        if ($("#frmSignIn").validationEngine('validate')) {
            clearNotifications();
            displayOverlay();

            // get and set parameters
            var parameters = {
                "role": {
                    "role-type": "public"
                },
                "data": {
                    "email": emailAddress,
                    "password": password,
                    "remember-me": rememberMe
                }
            };

            var loginSuccess = function (err, parameters, url, id, textStatus, jqXHR) {
                // Verify that we still have access to the value when the function is executed
                if (typeof rememberMe === "undefined") throw new Exception("Out of scope");

                // If the user is re-logging in, delete all data in case it's a different account
                if (Storage.is_initialized()) Storage.unset_all(false);

                displayMessage('success', 'Success! Signing in...');

                Storage.initialize(rememberMe ? Storage.LOCAL : Storage.SESSION);
                Storage.set("session-id", parameters.data["session-id"], Storage.LOCAL);
                Storage.set("role", {"role-type": "personal"}, Storage.LOCAL);

                callback(null);
            };

            var loginFailure = function (err, parameters, url, id, textStatus, jqXHR) {
                $('#txtPassword').val('');  // clear password field so they can start over
                callback('Invalid email/password combination. Please try again.');
            };

            server.request(exports.URL.SIGN_IN, parameters, "LOGIN VALIDATION")
                .success(loginSuccess)
                .failure(loginFailure)
                .delay(_.noop)
                .run();

            return false;
        } else {
            return false; // form not validated
        }
    };

    exports.getRoles = function (callback) {
        var loginSuccess = function (err, parameters, url, id, textStatus, jqXHR) {
            var sessionId = parameters.data["session-id"];
            var roleType = parameters.data["roles"];
            var moreThanPublic = false;
            var roles = parameters.data["roles"];

            for (var ii = 0; ii < roles.length; ii++) {
                if (roles[ii]["role-type"] != "public") {
                    moreThanPublic = true;
                }
            }

            var targetUrl;
            if (getQueryVariable("sign-in") == 'required') {
                targetUrl = location.href.substring(location.href.indexOf("url=") + 4, location.href.length);
            } else {
                if (!Storage.get("rolesSet")) {
                    if (moreThanPublic) {
                        console.log('more than public');
                        targetUrl = '/ui/users/select-your-role.php';
                    } else {
                        console.log('public only');
                        targetUrl = '/ui/public/index.php';
                    }
                }
            }
            callback(null, targetUrl);
        };

        var loginFailure = function (err, parameters, url, id, textStatus, jqXHR) {
            $('#txtPassword').val('');  // clear password field so they can start over
            callback('Invalid email/password combination. Please try again.')
        };

        var parameters = {
            "session-id": Storage.get("session-id"),
            "role": {
                "role-type": "public"
            },
            "data": {
                "person-id": "self"
            }
        };

        server.request(exports.URL.VIEW_USER_ROLES, parameters, "LOGIN VALIDATION")
            .success(loginSuccess)
            .failure(loginFailure)
            .delay(_.noop)
            .run();
    };

    exports.getZoneCss = function (callback) {
        // We do something a little tricky here.  Since, at a "personal" level permission, can see only its
        // LoginRole, setting the permission allows us to get the Jurisdiction information for the logged user
        // without knowing the LoginRole ID of the user.

        var query = {
            "rootAnchorAlias": "LR",
            "anchors": [
                {"mnemonic": "LR"},
                {"mnemonic": "PE"},
                {"alias": "home", "mnemonic": "JU", "optionalAttributes": ["NAM", "JUT"]},
                {"alias": "parent", "mnemonic": "JU", "optionalAttributes": ["NAM"]}
            ],
            "ties": [
                {
                    "mnemonic": "ALGN", "isRequired": true, "anchors": [
                        {"alias": "LR"},
                        {"alias": "PE"}
                    ]
                },
                {
                    "mnemonic": "JUPE", "isRequired": true, "anchors": [
                        {"alias": "PE"},
                        {"alias": "home"}
                    ]
                },
                {
                    "mnemonic": "JUPA", "isRequired": false, "anchors": [
                        {"alias": "home", "role": "isChild"},
                        {"alias": "parent", "role": "isParent"}
                    ]
                }
            ],
            "selects": [
                {"alias": "home", "attribute": "NAM", "as": "home_name"},
                {"alias": "home", "attribute": "JUT", "as": "home_type"},
                {"alias": "parent", "attribute": "NAM", "as": "parent_name"}
            ]
        };

        var successFunc = function (err, results) {
            switch (results.data.length) {
                case 0:
                    console.error("Person does not have a home jurisdiction");
                    break;
                case 1:
                    var data = results.data[0];
                    var zone_name;
                    if (data.home_type === "zone") zone_name = data.home_name;
                    else zone_name = data.parent_name;
                    zone_name = zone_name.toLowerCase().replace(/ /g, "-");
                    Storage.set("zone stylesheet path", "css/" + zone_name + ".css", Storage.LOCAL);
                    Storage.set("default zone", zone_name, Storage.LOCAL);
                    break;
                default:
                    console.error("Person has " + results.data.length + " home jurisdictions; expecting exactly 1.");
                    break;
            }

            callback(null);
        };

        var parameters = {
            "session-id": Storage.get("session-id"),
            "role": {"role-type": "personal"},
            "data": {"query": query}
        };

        server.request(Reports.URL.RUN, parameters, "Run Query")
            .success(successFunc)
            .failure(callback)
            .delay(_.noop)
            .run();
    };

    exports.processSignOut = function () {
        displayOverlay();

        //Check if the user is logged in.
        if (exports.isSignedIn()) {
            var sessionId = Storage.get("session-id");
            var parameters = exports.wrapData({"session-id": sessionId});

            var signOutSuccess = function (err, parameters, SIGN_OUT_URL, id, textStatus, jqXHR) {
                Storage.unset_all(false, Storage.ALL);
                window.location = '/ui/index.php?signout=true';
            };

            var signOutFailure = function (err, parameters, SIGN_OUT_URL, id, textStatus, jqXHR) {
                if (
                    parameters.code === 404 &&
                    (parameters.message === "Unknown session id") || (parameters.message === "Missing session-id")
                ) {
                    Storage.unset_all(true, Storage.ALL);
                    window.location = '/ui/index.php?signout=true';
                } else {
                    // display error message
                    hideOverlay();
                    displayMessage('error', 'Error signing out.');
                }
            };

            var signOutDelay = function (err, parameters, resource, id, textStatus, jqXHR) {
                hideOverlay();
                delayError();   // standard error handling for delay error
                displayMessage('delay');
            };

            server.request(exports.URL.SIGN_OUT, parameters, "SIGN OUT")
                .success(signOutSuccess)
                .failure(signOutFailure)
                .delay(signOutDelay)
                .run();

        } else {
            window.location = '/ui/index.php';
        }
    };

    /**
     * Does the client have a session-id?
     *
     * Note: does not check to see whether the server acknowledges the client as logged in.
     *
     * @returns {boolean}
     */
    exports.isSignedIn = function () {
        return Storage.isset("session-id");
    };

    exports.wrapData = function (data) {
        var parameters = {data: JSON.parse(JSON.stringify(data))};
        if (Storage.isset("session-id")) parameters["session-id"] = Storage.get("session-id");
        if (Storage.isset("role")) parameters["role"] = Storage.get("role");
        else parameters["role"] = {"role-type": "public"};
        return parameters;
    };

    exports.change_password = function (old_password, new_password, callback) {
        var data = {"existing-password": old_password, "new-password": new_password};
        var parameters = exports.wrapData(data);

        server.request(exports.URL.CHANGE_PASSWORD, parameters, "Change Password")
            .success(callback)
            .failure(callback)
            .delay(_.noop)
            .run();
    };
})(typeof exports === 'undefined' ? this['Security'] = {} : exports);