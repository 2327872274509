(function (exports) {
    "use strict";

    exports.URL = {};
    exports.URL.VIEW = "/operations/person-management/team-official-management/FN-ENT-009_view-team-official/FN-ENT-009_view-team-official.php";
    exports.URL.CREATE = "/operations/person-management/team-official-management/FN-ENT-005_create-team-official/FN-ENT-005_create-team-official.php";
    exports.URL.FIND = "/operations/person-management/team-official-management/FN-ENT-006_find-team-official/FN-ENT-006_find-team-official.php";
    exports.URL.FIND_APPLICABLE = "/operations/person-management/team-official-management/FN-ENT-014_find-applicable-team-officials/FN-ENT-014_find-applicable-team-officials.php";
    exports.URL.FIND_POTENTIAL_TEAM_OFFICIALS = "/operations/person-management/team-official-management/FN-ENT-013_find-potential-team-officials/FN-ENT-013_find-potential-team-officials.php";
    exports.URL.EDIT = "/operations/person-management/team-official-management/FN-ENT-007_edit-team-official/FN-ENT-007_edit-team-official.php";
    exports.URL.ACTIVATE = "/operations/person-management/team-official-management/FN-ENT-016_activate-team-official/FN-ENT-016_activate-team-official.php";
    exports.URL.REMOVE = "/operations/person-management/team-official-management/FN-ENT-008_delete-team-official/FN-ENT-008_delete-team-official.php";
    exports.URL.SEND_CONFIRMATION_EMAIL = "/operations/person-management/team-official-management/FN-ENT-009_send-registration-email/FN-ENT-009_send-registration-email.php";

    exports.URL.REPORTS = {};
    exports.URL.REPORTS.GENERAL = "/operations/person-management/team-official-management/FN-ENT-012_all-team-official-report/FN-ENT-012_all-team-official-report.php";
    exports.URL.REPORTS.ON_TEAMS = "/operations/person-management/team-official-management/FN-ENT-011_team-official-report/FN-ENT-011_team-official-report.php";
    exports.URL.REPORTS.DISCIPLINE = "/operations/person-management/team-official-management/FN-ENT-015_discipline-report/FN-ENT-015_discipline-report.php";
    exports.URL.REPORTS.ASA = "/operations/reporting/pre-defined-reports/FN-PDR-007_asa-team-officials/FN-PDR-007_asa-team-officials.php";
    exports.URL.REPORTS.UNSIGNED_SAR_WAIVERS = "/operations/reporting/pre-defined-reports/FN-PDR-015_team-official-unsigned-sar-waivers/FN-PDR-015_team-official-unsigned-sar-waivers.php";

    exports.URL.OPTIMIZATIONS = {};
    exports.URL.OPTIMIZATIONS.MANAGE_TEAM_OFFICIALS__GET_TEAM_OFFICIALS = "/operations/optimizations/manage-team-officials/get-team-officials/get-team-officials.php";

    exports.create = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("TeamOfficials", "create", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.CREATE, call, "Create Team Officials")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.find = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("TeamOfficials", "find", config);
        }

        var call = Security.wrapData(data);
        var loadConfig = {
            name: "Find Team Officials",
            url: exports.URL.FIND,
            callArrayName: "team-official-ids",
            responseArrayName: "team-officials",
            successFunc: config.successFunc,
            failureFunc: config.failureFunc,
            delayFunc: config.delayFunc
        };

        if (_.isUndefined(call.data["team-official-ids"])) Utilities.loadAll(call, loadConfig);
        else Utilities.loadSet(call, loadConfig);
    };

    exports.find_applicable = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Team Officials", "find_applicable", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.FIND_APPLICABLE, call, "Find Applicable Team Officials")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.find_potential_team_officials = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Team Officials", "find_potential_team_officials", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.FIND_POTENTIAL_TEAM_OFFICIALS, call, "Find Potential Team Officials")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.view = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("TeamOfficials", "view", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.VIEW, call, "View Team Officials")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.edit = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("TeamOfficials", "edit", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.EDIT, call, "Edit Team Officials")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.activate = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("TeamOfficials", "activate", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.ACTIVATE, call, "Activate Team Officials")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.remove = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("TeamOfficials", "remove", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REMOVE, call, "Remove Team Officials")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.send_confirmation_email = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("TeamOfficials", "send_confirmation_email", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.SEND_CONFIRMATION_EMAIL, call, "Send Confirmation Email")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.reports = {};
    exports.reports.general = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("TeamOfficials", "reports.general", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REPORTS.GENERAL, call, "Reports: General")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.reports.on_teams = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("TeamOfficials", "on_teams", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REPORTS.ON_TEAMS, call, "Report: Team Officials on Teams")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.reports.discipline = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("TeamOfficials", "discipline", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REPORTS.DISCIPLINE, call, "Report: Team Officials Discipline")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.reports.asa = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("TeamOfficials", "asa", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REPORTS.ASA, call, "Report: Team Official ASA")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.reports.unsigned_sar_waivers = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("TeamOfficials", "reports.unsigned_sar_waivers", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REPORTS.UNSIGNED_SAR_WAIVERS, call, "Reports: Team Officials Unsigned SAR Waivers")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.manage_team_officials__get_team_officials = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("TeamOfficials", "manage_team_officials__get_team_officials", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.OPTIMIZATIONS.MANAGE_TEAM_OFFICIALS__GET_TEAM_OFFICIALS, call, "manage_team_officials__get_team_officials")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.bulk_team_official_activation__get_team_officials = function (data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("TeamOfficials", "bulk_team_official_activation__get_team_officials", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.OPTIMIZATIONS.MANAGE_TEAM_OFFICIALS__GET_TEAM_OFFICIALS, call, "bulk_team_official_activation__get_team_officials")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };
})(typeof exports === 'undefined' ? this['TeamOfficials'] = {} : exports);