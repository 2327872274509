(function (exports) {
    "use strict";

    exports.URL = {};
    exports.URL.VIEW = "/operations/person-management/FN-ENM-017_view-invoice/FN-ENM-017_view-invoice.php";

    // Add and Create are pretty much the same thing.  Create is a copy of the Add code under a different name.  Both
    // options are provided here for backward compatibility.  Add should be removed during refactoring.
    exports.URL.ADD_TRANSACTION = "/operations/person-management/FN-ENM-018_make-payment/FN-ENM-018_make-payment.php";
    exports.URL.CREATE_TRANSACTION = "/operations/person-management/transaction-management/FN-TRM-001_create-transaction/FN-TRM-001_create-transaction.php";
    exports.URL.EDIT_TRANSACTION = "/operations/person-management/transaction-management/FN-TRM-002_edit-transaction/FN-TRM-002_edit-transaction.php";
    exports.URL.REMOVE_TRANSACTION = "/operations/person-management/transaction-management/FN-TRM-003_delete-transaction/FN-TRM-003_delete-transaction.php";
    exports.URL.REMOVE_REGISTRATION_TRANSACTIONS = "/operations/person-management/transaction-management/FN-TRM-004_remove-registration-transactions/FN-TRM-004_remove-registration-transactions.php";
    exports.URL.ADD_REGISTRATION_TRANSACTIONS = "/operations/person-management/transaction-management/FN-TRM-005_add-registration-transactions/FN-TRM-005_add-registration-transactions.php";

    exports.URL.MANAGE_PAYMENTS__GET_FEES = "/operations/optimizations/manage-payment/get-fees/get-fees.php";

    exports.view = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Transactions", "view", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.VIEW, call, "View Transaction")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.addTransaction = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Transactions", "addTransaction", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.ADD_TRANSACTION, call, "Add Transaction")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.create = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Transactions", "create", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.CREATE_TRANSACTION, call, "Create Transaction")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.edit = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Transactions", "edit", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.EDIT_TRANSACTION, call, "Edit Transaction")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.remove = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Transactions", "remove", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REMOVE_TRANSACTION, call, "Remove Transaction")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.remove_registration_transactions = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Transactions", "remove_registration_transactions", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REMOVE_REGISTRATION_TRANSACTIONS, call, "Remove Registration Transactions")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.add_registration_transactions = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Transactions", "add_registration_transactions", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.ADD_REGISTRATION_TRANSACTIONS, call, "Add Registration Transactions")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.manage_payments__get_fees = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Optimization", "Manage Payments::Get Fees", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.MANAGE_PAYMENTS__GET_FEES, call, "Manage Payments::Get Fees")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };
})(typeof exports === 'undefined' ? this['Transactions'] = {} : exports);