(function (exports) {
    "use strict";

    exports.URL = {};
    exports.URL.CREATE = "/operations/organizational-management/divisions/FN-OMD-001_create-division/FN-OMD-001_create-division.php";
    exports.URL.CLONE = "/operations/organizational-management/divisions/FN-OMD-002_clone-divisions/FN-OMD-002_clone-divisions.php";
    exports.URL.FIND = "/operations/organizational-management/divisions/FN-OMD-003_find-division/FN-OMD-003_find-division.php";
    exports.URL.VIEW = "/operations/organizational-management/divisions/FN-OMD-004_view-division-details/FN-OMD-004_view-division-details.php";
    exports.URL.EDIT = "/operations/organizational-management/divisions/FN-OMD-005_edit-division-details/FN-OMD-005_edit-division-details.php";
    exports.URL.REMOVE = "/operations/organizational-management/divisions/FN-OMD-006_delete-division/FN-OMD-006_delete-division.php";


    exports.create = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Divisions", "create", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.CREATE, call, "Create Division")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.clone = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Divisions", "clone", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.CLONE, call, "Clone Division")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.find = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Divisions", "find", config);
        }

        var call = Security.wrapData(data);
        var loadConfig = {
            name: "Find Divisions",
            url: exports.URL.FIND,
            callArrayName: "division-ids",
            responseArrayName: "divisions",
            successFunc: config.successFunc,
            failureFunc: config.failureFunc,
            delayFunc: config.delayFunc
        };

        if (_.isUndefined(call.data["division-ids"])) Utilities.loadAll(call, loadConfig);
        else Utilities.loadSet(call, loadConfig);
    };

    exports.view = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Divisions", "view", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.VIEW, call, "View Division")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.edit = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Divisions", "edit", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.EDIT, call, "Edit Division")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };

    exports.remove = function(data, config) {
        if (window.Operations_Common) {
            config = Operations_Common.defaultFunctions("Divisions", "remove", config);
        }

        var call = Security.wrapData(data);
        server.request(exports.URL.REMOVE, call, "Remove Division")
            .success(config.successFunc)
            .failure(config.failureFunc)
            .delay(config.delayFunc)
            .run();
    };
})(typeof exports === 'undefined' ? this['Divisions'] = {} : exports);