(function (exports) {
    "use strict";

    exports.defaultSuccessFunction = function (module_name, function_name, callback) {
        return function (err, parameters, resource, id, textStatus, jqXHR) {
            callback(null, parameters.data);
        };
    };

    exports.defaultFailureFunction = function (module_name, function_name, callback) {
        return function (err, parameters, resource, id, textStatus, jqXHR) {
            callback({
                "module": module_name,
                "function": function_name,
                "err": err,
                "parameters": parameters,
                "resource": resource,
                "id": id,
                "textStatus": jqXHR
            });
        };
    };

    exports.defaultDelayFunction = function (module_name, function_name, callback) {
        return function () {};
    };

    exports.defaultFunctions = function(module_name, function_name, configuration) {
        if (typeof configuration === "function") configuration = {callback: configuration};
        return augmentConfiguration(configuration, module_name, function_name);
    };

    var augmentConfiguration = function (configuration, module_name, function_name) {
        var config;
        if (_.isUndefined(configuration.callback)) {
            if (
                _.isUndefined(configuration.successFunc) ||
                _.isUndefined(configuration.failureFunc) ||
                _.isUndefined(configuration.delayFunc)
            ) {
                throw module_name + "." + function_name + ": can not augment callback configuration; missing items";
            } else {
                config = configuration;
            }
        } else {
            var callback = configuration.callback;
            config = {};
            config.successFunc = configuration.successFunc ? configuration.successFunc : exports.defaultSuccessFunction(module_name, function_name, callback);
            config.failureFunc = configuration.failureFunc ? configuration.failureFunc : exports.defaultFailureFunction(module_name, function_name, callback);
            config.delayFunc = configuration.delayFunc ? configuration.delayFunc : exports.defaultDelayFunction(module_name, function_name, callback);
        }
        return config;
    };
})(typeof exports === 'undefined' ? this['Operations_Common'] = {} : exports);